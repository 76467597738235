import { FC } from "react"

import SelectField from "../field/selectField/SelectField"
import FieldSkeleton from "../skeleton/fieldSkeleton/FieldSkeleton"
import {useTranslation} from "react-i18next"
import {useEmployerSelect} from "./EmployerSelect.hooks"

type Props = {
  name: string
}

const EmployerSelect: FC<Props> = ({ name }) => {
  const { t } = useTranslation()
  const { isLoading, isErrorOccurred, options } = useEmployerSelect()

  if (isLoading) {
    return <FieldSkeleton />
  }

  return (
    <SelectField
      name={name}
      label={t("usersList:employer")}
      options={options}
      error={isErrorOccurred}
      errorMessage={
        isErrorOccurred
          ? t("validations:failedToFetch")
          : undefined
      }
    />
  )
}

export default EmployerSelect
