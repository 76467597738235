import {useEffect} from "react"
import {BASE_URL_SSO} from "../../api/api.config"
import {useAppSelector} from "../../hooks/storeHooks/storeHooks"
import {selectLoginRedirectUrl} from "../../store/clinic/clinic.selectors"

export const useRedirectToLogin = () => {
  const loginRedirectUrl = useAppSelector(selectLoginRedirectUrl)

  useEffect(() => {
    window.location.href = loginRedirectUrl
  }, [loginRedirectUrl])
}

export const redirectToLogout = (xTenant: string) => {
  const redirectUrl = new URL(window.location.origin)
  redirectUrl.searchParams.set("clinicId", xTenant)

  const logoutSsoUrl = new URL(BASE_URL_SSO)
  logoutSsoUrl.pathname = "/logout"
  logoutSsoUrl.searchParams.set("logout_redirect", redirectUrl.toString())
  logoutSsoUrl.searchParams.set("x-tenant", xTenant)

  window.location.href = logoutSsoUrl.toString()
}
