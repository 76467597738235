import { CSSProperties } from "react"
import { palette } from "../palette"

export interface IStylesDictionary {
  // it is needed to use position: "absolute"
  [Key: string]: CSSProperties
}

const MuiAutocomplete = {
  inputRoot: {
    padding: "4px 72px 4px 20px !important",
    border: "2px solid",
    borderRadius: "8px",
    borderColor: palette.grey[200],
  },
  endAdornment: {
    backgroundColor: "transparent",
    right: "20px",
  },
  tag: {
    background: palette.primary.accent10,
    color: palette.primary.main,
    border: `solid 1px ${palette.primary.main}`,
    borderRadius: 9999,
    fontSize: "13px",
    fontWeight: 700,
  },
}

const MuiInput = {
  underline: {
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
}

const MuiInputBase = {
  input: {
    borderRadius: "8px",
    border: "none",
    height: "auto",
    padding: "10px 20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,

    "&:focus, &:active": {
      borderRadius: "8px",
      borderColor: palette.primary.main,
    },
  },
}

const MuiOutlinedInput = {
  input: {
    border: "2px solid",
    borderColor: palette.grey[200],
    padding: "10px 20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  multiline: {
    padding: 0,
  },
  inputMultiline: {
    padding: "10px 20px",
  },
  notchedOutline: {
    border: "none",
  },
  adornedEnd: {
    paddingRight: "0",
  },
}

const MuiInputAdornment: IStylesDictionary = {
  positionEnd: {
    position: "absolute",
    right: "8px",
    backgroundColor: palette.common.white,
    height: "100%",
    marginLeft: "0",
  },
}

export const inputs = {
  MuiAutocomplete,
  MuiInput,
  MuiInputBase,
  MuiOutlinedInput,
  MuiInputAdornment,
}
