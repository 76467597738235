import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

import { heights } from "../../themes/heights"

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: "8px 24px",
      position: "sticky",
      top: "0px",
      minHeight: heights.headerHeight,
      zIndex: 500,
    },
    link: {
      textDecoration: "none",
      color: theme.palette.common.white,
      "&.active": {
        borderBottom: "2px solid white",
      },
    },
    logoutBtn: {
      color: theme.palette.common.white,
    },
    employerName: {
      color: theme.palette.common.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
)

export default useHeaderStyles
