import { colors } from "../colors"

export const palette = {
  primary: {
    main: colors.telemediGreen,
    light: colors.telemediGreenLight,
    dark: colors.telemediGreenDark,
    accent10: colors.telemediGreenAccent10,
    accent50: colors.telemediGreenAccent50,
    accent100: colors.telemediGreenAccent100,
  },
  secondary: {
    main: colors.telemediBlue,
    dark: colors.telemediBlueDark,
    darker: colors.telemediBlueDarker,
    gradient: colors.telemediBlueGradient,
    accent10: colors.telemediBlueAccent10,
    accent20: colors.telemediBlueAccent20,
    accent60: colors.telemediBlueAccent60,
    accent70: colors.telemediBlueAccent70,
  },
  text: {
    primary: colors.telemediTextPrimary,
    secondary: colors.telemediTextSecondary,
    disabled: colors.telemediTextDisabled,
  },
  background: {
    default: colors.telemediPageBackground,
    paper: colors.telemediBackground,
    appBar: colors.telemediAppBarBg,
    tab: colors.telemediTabBackground,
  },
  grey: {
    100: colors.telemediGray100,
    200: colors.telemediGray200,
    300: colors.telemediGray300,
    400: colors.telemediGray400,
    500: colors.telemediGray500,
    700: colors.telemediGray700,
    900: colors.telemediGray900,
  },
  error: {
    main: colors.telemediRed,
  },
  common: {
    white: colors.telemediWhite,
    black: colors.telemediBlack,
  },
}
