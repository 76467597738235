import { Box, Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const TableSkeleton = () => {
  const rowsNumber = 6

  const rowsIterator = new Array(rowsNumber).fill(1).map((_, i) => i)

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Skeleton width="100%" height="38px" variant="rect" />
      </Grid>
      {rowsIterator.map((el) => (
        <Grid item xs={12} key={el}>
          <Skeleton width="100%" height="50px" variant="rect" />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="end">
          <Skeleton width="250px" height="32px" variant="rect" />
        </Box>
      </Grid>
    </Grid>
  )
}

export default TableSkeleton
