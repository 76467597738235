import {createSlice, PayloadAction} from "@reduxjs/toolkit"

import { IUser } from "../../ts/interfaces/User"
import { getUser } from "./user.thunks"
import { Role } from "../../api/roles.types"

const initialState: IUser = {
  data: null,
  loading: false,
  error: false,
  authenticated: false,
  userChecked: false,
  isInterceptorReady: false,
  shouldShowEmployerFields: false,
  roles: [],
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setIsInterceptorReady: (state) => {
      state.isInterceptorReady = true
    },
    setShouldShowEmployerFields: (state, action: PayloadAction<boolean>) => {
      state.shouldShowEmployerFields = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // getUser thunk
      .addCase(getUser.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.loading = false

        const userRoles = payload?.token?.["v4-roles"]
        const isAllClinicsAdmin =
          !!userRoles?.includes(Role.ROLE_ALL_CLINICS_ADMIN) ||
          !!userRoles?.includes(Role.ROLE_CLINIC_ADMIN)

        if (userRoles) {
          state.roles = userRoles
        }

        state.data = { ...payload, isAllClinicsAdmin }
        state.authenticated = "token" in payload
        state.userChecked = true
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export const { setData, setIsInterceptorReady, setShouldShowEmployerFields } = userSlice.actions

export default userSlice.reducer
