import { palette } from "../palette"

const MuiPaginationItem = {
  root: {
    borderRadius: "4px",
    color: palette.text.secondary,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  page: {
    "&.Mui-selected": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
      fontWeight: 700,
    },
  },
}

export const paginations = {
  MuiPaginationItem,
}
