import i18n from "../../../i18n/i18n"

export const steps = [
  {
    number: 1,
    name: i18n.t("addReferral:exposures"),
  },
  {
    number: 2,
    name: i18n.t("addReferral:referral"),
  },
]
