import { useQuery } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"

export const useQueryDownload = (
  id: string,
  name: string,
  type: string,
  endpoint: string,
  cacheName: string,
  enabled: boolean
) => {
  return useQuery<void, AxiosError>(
    [cacheName, id],
    async () => {
      const link = document.createElement("a")
      link.target = "_blank"
      link.download = name

      const res = await axios(endpoint, {
        responseType: "blob",
      })

      link.href = URL.createObjectURL(new Blob([res.data], { type }))
      link.click()
      link.remove()
    },
    {
      staleTime: 20 * 1000,
      enabled,
    }
  )
}
