import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import envConfig from "../../constants/envConfig"
import store from "../../store"

const asyncAuthLink = setContext(async (operation, previousContext) => {
  let context = previousContext
  const { headers } = previousContext
  const token = store.getState().user.data?.token?.accessToken

  context =  {
    ...previousContext,
    headers: {
      ...headers,
      "Authorization": `Bearer ${token}`
    }
  }

  return context
})

const HrModuleLink = new HttpLink({
  uri: `${envConfig.hrHasuraUrl}/v1/graphql`,
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    asyncAuthLink,
    HrModuleLink
  ]),
  cache: new InMemoryCache(),
})

export {
  apolloClient
}