const MuiAlert = {
  message: {
    fontWeight: 700,
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
  },
}

export const alert = {
  MuiAlert,
}
