import { FC } from "react"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import PageContentLayout from "../../../layout/pageContentLayout/PageContentLayout"
import Paper from "../../../components/common/paper/Paper"
import GoBackLink from "../../../components/common/link/goBackLink/GoBackLink"
import AddEmployee from "../../../components/form/addEmployee/AddEmployee"

const EmployeeAddPage: FC = () => {
  const { t } = useTranslation()

  return (
    <PageContentLayout>
      <Paper>
        <GoBackLink text={t("goBackToEmployeesList")} />
        <Box mt="24px">
          <AddEmployee />
        </Box>
      </Paper>
    </PageContentLayout>
  )
}

export default EmployeeAddPage
