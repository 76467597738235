import { FC, useState } from "react"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import TablePopover from "./tablePopover/TablePopover"
import useUtilsStyles from "../../../../utils/utils.styles"
import usePatientsTableStyles from "./PatientsTable.styles"
import { IPatientNoExternalData } from "../../../../ts/interfaces/Patient"
import StatusText from "../../../common/statusText/StatusText"
import TableHeader from "./tableHeader/TableHeader"
import TableBody from "./tableBody/TableBody"

interface IProps {
  data: IPatientNoExternalData[]
}

const PatientsTable: FC<IProps> = ({ data }) => {
  const { t } = useTranslation()
  const classes = usePatientsTableStyles()
  const utilClasses = useUtilsStyles()
  const [chosenPatient, setChosenPatient] =
    useState<IPatientNoExternalData | null>(null)
  const [popoverOpened, setPopoverOpened] = useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const handleActionButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    patient: IPatientNoExternalData
  ) => {
    setPopoverAnchorEl(event.currentTarget)
    setChosenPatient(patient)
    setPopoverOpened(true)
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
    setChosenPatient(null)
    setPopoverOpened(false)
  }

  return (
    <>
      <section className={classes.tableWrapper}>
        <table className={classes.table}>
          <TableHeader />
          {data.length > 0 && (
            <TableBody
              data={data}
              handleActionButtonClick={handleActionButtonClick}
            />
          )}
        </table>
        {data.length === 0 && (
          <Box px="16px" py="12px" className={utilClasses.textPrimaryMain}>
            <StatusText text={t("validations:noResults")} />
          </Box>
        )}
      </section>
      {chosenPatient && (
        <TablePopover
          open={popoverOpened}
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
          patient={chosenPatient}
        />
      )}
    </>
  )
}

export default PatientsTable
