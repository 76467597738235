import { add } from "date-fns"
import { IMedicalReport } from "../../../../../ts/interfaces/MedicalReport"

export const getNewestDate = (medicalReports: IMedicalReport[]) => {
  return new Date(
    Math.max(
      ...medicalReports.map((medicalReport) =>
        Date.parse(new Date(medicalReport.validUntil).toString())
      )
    )
  )
}

export const isNewestMedicalReportGoingToExpire = (
  medicalReports: IMedicalReport[]
) => {
  if (!medicalReports.length) return false
  const newestMedicalReportDate = getNewestDate(medicalReports)

  const expirationDate = add(new Date(), {
    days: 30,
  })

  return (
    Date.parse(newestMedicalReportDate.toDateString()) -
      Date.parse(expirationDate.toDateString()) <=
    0
  )
}
