import { FC } from "react"
import { Box } from "@material-ui/core"

import theme from "../../../themes/default/theme"

const Paper: FC = ({ children }) => {
  return (
    <Box px="24px" py="40px" width="100%" bgcolor={theme.palette.common.white}>
      {children}
    </Box>
  )
}

export default Paper
