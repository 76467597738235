import { FC } from "react"
import { Box } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const FieldSkeleton: FC = () => {
  return (
    <Box>
      <Box mb="4px">
        <Skeleton width="80px" height="20px" variant="rect" />
      </Box>
      <Skeleton width="100%" height="48px" variant="rect" />
    </Box>
  )
}

export default FieldSkeleton
