import { configureStore } from "@reduxjs/toolkit"

import userReducer from "./user/user.slice"
import patientsListReducer from "./patientsList/patientsList.slice"
import clinicReducer from "./clinic/clinic.slice"

export default configureStore({
  reducer: {
    user: userReducer,
    patientsList: patientsListReducer,
    clinic: clinicReducer,
  },
})
