import { FC, useEffect } from "react"
import { Box, Button, Typography } from "@material-ui/core"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import useUtilsStyles from "../../../utils/utils.styles"
import {
  IProps,
  IUploadPatientReportFormState,
} from "./UploadPatientReport.types"
import ButtonLoader from "../../common/button/buttonLoader/ButtonLoader"
import { useMutationPostMedicalReport } from "../../../api/reactQuery/mutations/patients"
import UploadFormFields from "./uploadFormFields/UploadFormFields"
import Snackbar from "../../common/snackbar/Snackbar"
import theme from "../../../themes/default/theme"

const UploadPatientReport: FC<IProps> = ({
  onButtonCancelClick,
  patientId,
}) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()

  const methods = useForm<IUploadPatientReportFormState>({
    mode: "onSubmit",
    defaultValues: {
      file: null,
      validUntil: new Date(),
    },
  })

  const { mutate, isLoading, isError, isSuccess } =
    useMutationPostMedicalReport()

  const onFormSubmit: SubmitHandler<IUploadPatientReportFormState> = (data) => {
    mutate({
      patientId,
      file: data?.file?.[0] ?? undefined,
      validUntil: data.validUntil.toISOString(),
    })
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (isSuccess) {
      timer = setTimeout(() => {
        onButtonCancelClick()
      }, 2000)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [isSuccess])

  return (
    <Box p="48px">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Box display="flex" justifyContent='center' alignItems="center" mb="32px">
            <Box mr="8px">
              <Typography variant="h5" align="center">{t("defaultTranslations:chooseFile")}</Typography>
            </Box>
            <Box color={theme.palette.error.main}>
              <Typography variant="body2" align="center">{t("defaultTranslations:optional")}</Typography>
            </Box>
          </Box>
          <Box mb="40px">
            <UploadFormFields />
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            className={utilClasses.gap16}
          >
            <Button variant="text" onClick={onButtonCancelClick}>
              {t("cancel")}
            </Button>
            <ButtonLoader
              type="submit"
              loading={isLoading}
              width="80px"
            >
              {t("send")}
            </ButtonLoader>
          </Box>
        </form>
        <Snackbar
          isVisible={isError}
          text={t("validations:errorOccuredTryAgain")}
          severity="error"
        />
        <Snackbar
          isVisible={isSuccess}
          text={t("employeeForm:attachmentAdded")}
        />
      </FormProvider>
    </Box>
  )
}

export default UploadPatientReport
