import { FC } from "react"
import { Box, BoxProps, Typography } from "@material-ui/core"

import useUtilsStyles from "../../../../utils/utils.styles"

interface IProps extends BoxProps {
  title?: string
}

const SectionTitle: FC<IProps> = ({ title, mb = "8px", ...props }) => {
  const utilClasses = useUtilsStyles()

  return (
    <Box mb={mb} {...props}>
      <Typography variant="subtitle1" className={utilClasses.fontBold}>
        {title}
      </Typography>
    </Box>
  )
}

export default SectionTitle
