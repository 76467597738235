import {useSetTenantToken} from "./useSetTenantToken"
import {useAppDispatch,} from "../../../../hooks/storeHooks/storeHooks"
import {useEffect} from "react"
import {getUser, redirectToLogin} from "../../../../store/user/user.thunks"

export const useGetUser = () => {
  const hasSetTenantToken = useSetTenantToken()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!hasSetTenantToken) {
      return
    }

    dispatch(getUser({ shouldIgnoreRedirect: true }))
      .then(data => {
        // @ts-ignore
        //todo: remove ts-ignore
        if (data?.error?.message === "Rejected") {
          dispatch(redirectToLogin())
        }
      })
  }, [hasSetTenantToken])
}
