import { FC } from "react"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import FormSection from "../formSection/FormSection"
import PersonalDataFields from "./personalDataFields/PersonalDataFields"
import AddressFields from "./addressFields/AddressFields"

const EmployeeFields: FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormSection title={t("employeeForm:personalData")}>
        <PersonalDataFields />
      </FormSection>
      <Box mt="16px">
        <FormSection title={t("employeeForm:homeAddress")}>
          <AddressFields />
        </FormSection>
      </Box>
    </Box>
  )
}

export default EmployeeFields
