export const defaultTranslations = {
  accept: "Akceptuj",
  finish: "Zakończ",
  cancel: "Anuluj",
  delete: "Usuń",
  send: "Wyślij",
  run: "Uruchom",
  close: "Zamknij",
  loadingDots: "Ładowanie...",
  noResults: "Brak wyników",
  active: "Aktywny",
  notActive: "Nieaktywny",
  edition: "Edycja",
  logs: "Logi",
  referral: "Skierowanie",
  goBack: "Wróć",
  goBackToEmployeesList: "Wróć do listy pracowników",
  since: "Od",
  until: "Do",
  status: "Status",
  activate: "Aktywuj",
  bundles: "Pakiety",
  yes: "Tak",
  search: "Szukaj",
  filters: "Filtry",
  add: "Dodaj",
  chooseFile: "Wybierz plik",
  optional: "(nieobowiązkowe)",
}
