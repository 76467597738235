import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"

import useUtilsStyles from "../../../../../utils/utils.styles"

interface IProps {
  label: string
  value: string | number
  widthLabel?: number
}

const FrameCell: FC<IProps> = ({ label, value, widthLabel = 120 }) => {
  const utilClasses = useUtilsStyles()

  return (
    <Box display="flex" alignItems="center">
      <Box mr="8px" width={widthLabel} className={utilClasses.shrink0}>
        <Typography
          variant="body2"
          className={clsx(utilClasses.textTextSecondary, utilClasses.fontBold)}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="subtitle1"
        className={clsx(utilClasses.fontSemibold)}
      >
        {value === "" || value === 0 ? "-" : value}
      </Typography>
    </Box>
  )
}

export default FrameCell