import { Box, Typography } from "@material-ui/core"
import { FC } from "react"
import { useTranslation } from "react-i18next"

import { steps } from "../AddReferral.config"
import StepIndicator from "./stepIndicator/StepIndicator"
import useUtilsStyles from "../../../../utils/utils.styles"

interface IProps {
  currentStep: number
}

const FormHeader: FC<IProps> = ({ currentStep }) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()

  return (
    <Box
      my="24px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h2">
        {t("addReferral:createOccupationalMedicineReferral")}
      </Typography>
      <Box display="flex" className={utilClasses.gap24}>
        {steps.map((step) => (
          <StepIndicator
            key={step.number}
            text={step.name}
            stepNumber={step.number}
            active={currentStep >= step.number}
          />
        ))}
      </Box>
    </Box>
  )
}

export default FormHeader
