import { FC } from "react"

import { Box, Button, Grid, Typography } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconFilter } from "../../../../../assets/svg/filter.svg"
import { ReactComponent as IconReset } from "../../../../../assets/svg/reset.svg"
import {
  bundleStatuses,
  defaultValues,
  occupationalMedicineStatuses,
} from "./FiltersForm.config"
import { IProps, ISearchFormState } from "./FiltersForm.types"
import TextField from "../../../../common/field/textField/TextField"
import SelectField from "../../../../common/field/selectField/SelectField"
import DateField from "../../../../common/field/dateField/DateField"
import CheckboxField from "../../../../common/field/checkboxField/CheckboxField"
import useFiltersFormStyles from "./FiltersForm.styles"
import useUtilsStyles from "../../../../../utils/utils.styles"
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/storeHooks/storeHooks"
import { selectIsFilterOpened } from "../../../../../store/patientsList/patientsList.selectors"
import { toggleFilters } from "../../../../../store/patientsList/patientsList.slice"
import EmployerSelect from "../../../../common/employerSelect/EmployerSelect"

const FiltersForm: FC<IProps> = ({ onSubmit, initialValues }) => {
  const shouldShowEmployerFields = useAppSelector(state => state.user.shouldShowEmployerFields)
  const dispatch = useAppDispatch()
  const isFilterOpened = useAppSelector(selectIsFilterOpened)

  const classes = useFiltersFormStyles({ isOpened: isFilterOpened })
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()

  const methods = useForm<ISearchFormState>({
    defaultValues: initialValues || defaultValues,
    mode: "onSubmit",
  })

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            <Box
              width="max-content"
              display="flex"
              alignItems="center"
              mb="32px"
              className={utilClasses.cursorPointer}
              onClick={() => dispatch(toggleFilters())}
            >
              <IconFilter className={classes.filterIcon} />
              <Box ml="8px">{t("filters")}</Box>
            </Box>
          </Typography>
          <Typography variant="h5">
            <Box
              width="max-content"
              display="flex"
              alignItems="center"
              mb="32px"
              className={utilClasses.cursorPointer}
              onClick={() => {
                methods.reset(defaultValues)
                methods.handleSubmit(onSubmit)()
              }}
            >
              <IconReset />
              <Box ml="8px">{t("usersList:resetFilters")}</Box>
            </Box>
          </Typography>
        </Box>
        <Box className={classes.filtersBox}>
          <Grid container spacing={3}>
            {
              shouldShowEmployerFields && (
                <Grid item xs={3}>
                  <EmployerSelect name='employer' />
                </Grid>
              )
            }
            <Grid item xs={3}>
              <SelectField
                name="status"
                label={t("usersList:status")}
                options={bundleStatuses}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField name="firstName" label={t("usersList:firstName")} />
            </Grid>
            <Grid item xs={3}>
              <TextField name="lastName" label={t("usersList:lastName")} />
            </Grid>
            <Grid item xs={3}>
              <DateField name="validSince" label={t("usersList:validSince")} />
            </Grid>
            <Grid item xs={3}>
              <DateField name="validUntil" label={t("usersList:validUntil")} />
            </Grid>
            {/* https://telemedico.atlassian.net/browse/LESS-3135 */}
            {/* Hide fields because they are not used */}
            {/*<Grid item xs={3}>*/}
            {/*  <DateField*/}
            {/*    name="activeSince"*/}
            {/*    label={t("usersList:activeSince")}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*  <DateField*/}
            {/*    name="activeUntil"*/}
            {/*    label={t("usersList:activeUntil")}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={3}>
              <TextField name="idNumber" label={t("usersList:idNumber")} />
            </Grid>
            {/* https://telemedico.atlassian.net/browse/LESS-3135 */}
            {/* Hide fields because they are not used */}
            {/*<Grid item xs={3}>*/}
            {/*  {isLoadingBundles && <FieldSkeleton />}*/}
            {/*  {!isLoadingBundles && bundlesData?.data && (*/}
            {/*    <SelectField*/}
            {/*      name="bundle"*/}
            {/*      label={t("usersList:bundle")}*/}
            {/*      options={*/}
            {/*        bundlesData?.data*/}
            {/*          ? [*/}
            {/*              { value: "", label: "-" },*/}
            {/*              ...bundlesData.data.map((bundle) => ({*/}
            {/*                value: bundle.name,*/}
            {/*                label: bundle.name,*/}
            {/*              })),*/}
            {/*            ]*/}
            {/*          : []*/}
            {/*      }*/}
            {/*      error={isErrorBundles}*/}
            {/*      errorMessage={*/}
            {/*        isErrorBundles ? t("validations:failedToFetch") : undefined*/}
            {/*      }*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</Grid>*/}
            <Grid item xs={3}>
              <SelectField
                name="occupationalMedicineStatus"
                label={t("usersList:occupationalMedicineStatus")}
                options={occupationalMedicineStatuses}
              />
            </Grid>
            <Grid item xs={3}>
              <Box pt="24px">
                <CheckboxField
                  name="showPatientsWithExpiredMedicine"
                  label={t("usersList:medicalOpinionsGoingToExpire")}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt="40px" display="flex" justifyContent="center">
            <Box width="320px">
              <Button type="submit" fullWidth>
                {t("search")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default FiltersForm
