import { buttonBase } from "./buttonBase"
import { typography } from "./typography"
import { select } from "./select"
import { checkbox } from "./checkbox"

export const props = {
  ...buttonBase,
  ...typography,
  ...select,
  ...checkbox,
}
