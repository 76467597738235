import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { IReferral } from "../../../../ts/interfaces/Referral"
import { IMedicalReport } from "../../../../ts/interfaces/MedicalReport"
import {IPatient} from "../../../../ts/interfaces/Patient"
import { format } from "date-fns"
import { ValidUntilPicker } from "./ValidUntilPicker/ValidUntilPicker.component"
import { DeleteDocument } from "./DeleteDocument/DeleteDocument.component"
import { useMutationDeleteReferral } from "../../../../api/reactQuery/mutations/referrals"
import { useMutationDeleteMedicalReport } from "../../../../api/reactQuery/mutations/medicalReports"
import { PrintMedicalReport } from "./PrintDocument/PrintMedicalReport/PrintMedicalReport.component"
import { PrintReferral } from "./PrintDocument/PrintReferral/PrintReferral.component"
import { DownloadReferral } from "./DownloadDocument/DownloadReferral/DownloadReferral.component"
import { DownloadMedicalReport } from "./DownloadDocument/DownloadMedicalReport/DownloadMedicalReport.component"
import { getReferralFileName } from "./DocumentsTable.utils"

export const useDocumentsTable = (
  patient: IPatient,
  referrals: IReferral[],
  medicalReports: IMedicalReport[],
) => {
  const { t } = useTranslation()

  const {
    mutate: deleteReferral,
    isLoading: isDeleteReferralLoading,
    isError: isDeleteReferralError,
  } = useMutationDeleteReferral()

  const {
    mutate: deleteReport,
    isLoading: isDeleteReportLoading,
    isError: isDeleteReportError,
  } = useMutationDeleteMedicalReport()

  const createData = (
    id: string,
    type: string,
    createdAt: string,
    preview: ReactNode | null,
    validUntil: ReactNode | null,
    printIcon: ReactNode,
    deleteIcon: ReactNode,
  ) => {
    return { id, type, createdAt, preview, validUntil, printIcon, deleteIcon}
  }

  const parsedReports = medicalReports.map((report) => {
    return createData(
      report.id,
      t("employeeForm:documentTypes:medicalReport"),
      format(new Date(report.createdAt), "dd.MM.yyyy"),
      report.file && (
        <DownloadMedicalReport
          id={report.id}
          name={getReferralFileName(patient, t("employeeForm:occupationalMedicineOpinionPreview"))}
        />
      ),
      (
        <ValidUntilPicker
          id={report.id}
          validUntil={new Date(report.validUntil)}
        />
      ),
      report.file && (
        <PrintMedicalReport
          id={report.id}
        />
      ),
      (
        <DeleteDocument
          label={t("employeeForm:areYouSureYouWantToDeleteMedicalReport")}
          onSubmit={() => deleteReport(report.id)}
          isLoading={isDeleteReportLoading}
          isError={isDeleteReportError}
        />
      )
    )
  })

  const parsedReferrals = referrals.map((referral) => {
    return createData(
      referral.id,
      t("employeeForm:documentTypes:referral"),
      format(new Date(referral.createdAt), "dd.MM.yyyy"),
      (
        <DownloadReferral
          id={referral.id}
          name={getReferralFileName(patient, t("employeeForm:occupationalMedicineReferralPreview"))}
          isLoading={isDeleteReferralLoading}
        />
      ),
      null,
      (
        <PrintReferral
          id={referral.id}
        />
      ),
      (
        <DeleteDocument
          label={t("employeeForm:areYouSureYouWantToDeleteReferral")}
          onSubmit={() => deleteReferral(referral.id)}
          isLoading={isDeleteReferralLoading}
          isError={isDeleteReferralError}
        />
      )
    )
  })

  return {
    parsedReports,
    parsedReferrals
  }
}