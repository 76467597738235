import { FC } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import TextField from "../../../field/textField/TextField"

const AddressFields: FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={6}>
        <TextField
          name="address"
          label={t("employeeForm:street")}
          placeholder={t("employeeForm:enterStreetAndHouseNumber")}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="postalCode"
          label={t("employeeForm:postalCode")}
          placeholder="00-000"
          mask="99-999"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="city"
          label={t("employeeForm:city")}
          placeholder={t("employeeForm:enterCity")}
        />
      </Grid>
    </>
  )
}

export default AddressFields
