import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

import Dialog from "../../../../../common/dialog/dialog/Dialog"
import ButtonClose from "../../../../../common/button/buttonClose/ButtonClose"
import { useQueryPatient } from "../../../../../../api/reactQuery/queries/patients"
import useUtilsStyles from "../../../../../../utils/utils.styles"
import StatusText from "../../../../../common/statusText/StatusText"
import LogsTable from "./logsTable/LogsTable"

interface IProps {
  open: boolean
  onClose: () => void
  patientId: string
}

const LogsDialog: FC<IProps> = ({ open, onClose, patientId }) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()

  const { data, isLoading, isError } = useQueryPatient(patientId)

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <Box px="48px" py="24px">
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            mb="24px"
          >
            <Typography variant="h2">
              {t("usersList:employeeLogs")}
              {" - "}
              {`${data?.firstName || ""} ${data?.lastName || ""}`}
            </Typography>
            <ButtonClose onClick={onClose} />
          </Box>
          <Box>
            {isError && (
              <Box className={utilClasses.textErrorMain}>
                <StatusText text={t("validations:errorOccuredTryAgain")} />
              </Box>
            )}
            {data && !data.logs.length && (
              <Box className={utilClasses.textPrimaryMain}>
                <StatusText text={t("usersList:noLogs")} />
              </Box>
            )}
            {data && <LogsTable logs={data.logs} />}
          </Box>
        </>
        {isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            className={utilClasses.gap16}
          >
            <Skeleton variant="rect" width="100%" height="16px" />
            <Skeleton variant="rect" width="100%" height="24px" />
            <Skeleton variant="rect" width="100%" height="24px" />
            <Skeleton variant="rect" width="100%" height="24px" />
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default LogsDialog
