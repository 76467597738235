import { FC } from "react"

import { Checkbox, FormControlLabel } from "@material-ui/core"
import { Controller, useFormContext } from "react-hook-form"

import { IProps } from "./CheckboxField.types"

const CheckboxField: FC<IProps> = ({
  name,
  label,
  labelPlacement = "end",
  ...props
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              checked={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              inputRef={field.ref}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
    />
  )
}

export default CheckboxField
