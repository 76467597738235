import { BundleStatus } from "../../../../../ts/enums/Bundle"
import i18n from "../../../../../i18n/i18n"
import { ISearchFormState } from "./FiltersForm.types"

export const defaultValues: ISearchFormState = {
  employer: "",
  status: "",
  firstName: "",
  lastName: "",
  idNumber: "",
  bundle: "",
  activeSince: null,
  activeUntil: null,
  validSince: null,
  validUntil: null,
  occupationalMedicineStatus: "",
  showPatientsWithExpiredMedicine: false,
}

export const bundleStatuses = [
  {
    value: "",
    label: "-",
  },
  {
    value: BundleStatus.ACTIVE,
    label: i18n.t("active"),
  },
  {
    value: BundleStatus.INACTIVE,
    label: i18n.t("notActive"),
  },
]

export const occupationalMedicineStatuses = [
  {
    value: "",
    label: "-",
  },
  {
    value: "no_referrals",
    label: i18n.t("usersList:noReferral"),
  },
  {
    value: "issued_referrals",
    label: i18n.t("usersList:issuedReferral"),
  },
  {
    value: "medical_report_issued",
    label: i18n.t("usersList:medicalReportIssued"),
  },
]
