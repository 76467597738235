import { ListItem, List } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { JobTypes } from "./FormFields.enum"
import { useFormFieldsStyles } from "./FormFields.styles"
import { IInputOption } from "../../../../ts/interfaces/FormField"
import { IExposure } from "../../../../ts/interfaces/Exposure"

export const useFormFields = () => {
  const { list } = useFormFieldsStyles()
  const { t } = useTranslation()

  const getJobTypeTooltip = (jobType: JobTypes) => {
    switch (jobType) {
      case JobTypes.TYPE1:
        return (
          <List className={list}>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item1")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item2")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item3")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item4")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item5")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item6")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item7")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type1:tooltipItems:item8")}</ListItem>
          </List>
        )
        case JobTypes.TYPE2:
        return (
          <List className={list}>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item1")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item2")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item3")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item4")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item5")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item6")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item7")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item8")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item9")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type2:tooltipItems:item10")}</ListItem>
          </List>
        )
        case JobTypes.TYPE3:
        return (
          <List className={list}>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item1")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item2")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item3")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item4")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item5")}</ListItem>
            <ListItem>{t("addReferral:jobTypes:type3:tooltipItems:item6")}</ListItem>
          </List>
        )
    }
  }

  const mapExposuresToFormValues = (exposureApiIds: string[], exposures: IExposure[]) => {
    const physicalFactors: IInputOption[] = []
    const dusts: IInputOption[] = []
    const chemicalFactors: IInputOption[] = []
    const biologicalFactors: IInputOption[] = []
    const otherFactors: IInputOption[] = []

    if (!exposures) {
      return {}
    }

    exposureApiIds.forEach((exposureApiId: string) => {
      const splitUrl = exposureApiId.split("/")
      const exposureId = splitUrl[splitUrl.length - 1]

      const exposure = exposures
        .find((exposure: IExposure) => exposure.id === exposureId)

      if ( !exposure ) {
        return
      }

      const parsedData = { value: exposure.id, label: exposure.name }
      switch (exposure.type) {
        case "physical":
          physicalFactors.push(parsedData)
          break
        case "dust":
          dusts.push(parsedData)
          break
        case "chemical":
          chemicalFactors.push(parsedData)
          break
        case "biological":
          biologicalFactors.push(parsedData)
          break
        case "other":
          otherFactors.push(parsedData)
          break
        default:
          break
      }
    })

    return {
      physicalFactors,
      dusts,
      chemicalFactors,
      biologicalFactors,
      otherFactors
    }
  }

  return {
    getJobTypeTooltip,
    mapExposuresToFormValues
  }
}
