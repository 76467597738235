import { useEffect } from "react"

import axiosInstance from "../../../../api/api"
import {getUser, logoutUser} from "../../../../store/user/user.thunks"
import { useAppDispatch } from "../../../../hooks/storeHooks/storeHooks"
import {isUserData} from "../../../../ts/typeGuard/isUserData"

export const useAxiosAuthInterceptors = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      undefined,
      async (err) => {
        const originalConfig = err.config
        if (err.response) {
          //todo: https://telemedico.atlassian.net/browse/LESS-3155, change condition to check only 401
          if (err.response.status > 400 && !originalConfig._retry) {
            originalConfig._retry = true
            try {
              const response = await dispatch(getUser())

              if (isUserData(response.payload)) {
                originalConfig.headers["Authorization"] = `Bearer ${response.payload.token?.accessToken}`
              }

              return axiosInstance(originalConfig)
            } catch (_error) {
              return Promise.reject(_error)
            }
          } else if (err.response.status > 400) {
            dispatch(logoutUser())
          }
        }
        return Promise.reject(err)
      }
    )
  }, [])

  return null
}
