import ReactDOM from "react-dom"
import "./i18n/i18n"
import "./utils/yup/setLocale"
import "./styles/index.css"
import App from "./App"
import AppProviders from "./core/appPorvider/AppProviders"

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
)
