import { useQuery } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import { IExposure } from "../../../ts/interfaces/Exposure"

export const useQueryExposures = () => {
  return useQuery<IExposure[], AxiosError>(
    ["exposures"],
    async () => {
      const res = await axios(Endpoint.EXPOSURES)
      return res.data
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )
}
