import { FC } from "react"
import { Dialog as DialogMUI, DialogProps } from "@material-ui/core"

const Dialog: FC<DialogProps> = ({
  children,
  open,
  onClose,
  maxWidth = "md",
  fullWidth = true,
  scroll = "body",
  ...props
}) => {
  return (
    <DialogMUI
      open={open}
      onClose={onClose}
      scroll={scroll}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </DialogMUI>
  )
}

export default Dialog
