import {gql} from "@apollo/client"

export const ADD_REFERRAL_TEMPLATE = gql`
  mutation addReferralTemplate(
    $name: String!,
    $employerId: String!
  ) {
    insertReferralTemplates: insert_referral_templates(
      objects: {
        name: $name,
        employer_id: $employerId
      }) {
        returning {
          id
        }
      }
    }
`