import { palette } from "../palette"

const MuiChip = {
  deleteIcon: {
    color: palette.primary.main,
  },
}

export const chip = {
  MuiChip,
}
