import { FC, useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import EmployeeFields from "../../common/form/employeeFields/EmployeeFields"
import {
  employeeFieldsDefaultValues,
  employeeFieldsSchema,
} from "../../common/form/employeeFields/EmployeeFields.config"
import { IEmployeeFields } from "../../common/form/employeeFields/EmployeeFields.types"
import { useMutationPostPatients } from "../../../api/reactQuery/mutations/patients"
import { RoutePath } from "../../../routes/Routes.types"
import ButtonLoader from "../../common/button/buttonLoader/ButtonLoader"
import Snackbar from "../../common/snackbar/Snackbar"

const AddEmployee: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const methods = useForm<IEmployeeFields>({
    resolver: yupResolver(employeeFieldsSchema),
    mode: "onSubmit",
    defaultValues: employeeFieldsDefaultValues,
  })

  const { mutate, data, isLoading, isError } = useMutationPostPatients()

  const formSubmit: SubmitHandler<IEmployeeFields> = (data) => {
    mutate(data)
  }

  useEffect(() => {
    if (data) {
      navigate(RoutePath.HOMEPAGE)
    }
  }, [data])

  return (
    <Box>
      <Typography variant="h2">{t("employeeForm:addEmployee")}</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(formSubmit)}>
          <Box mt="32px">
            <EmployeeFields />
          </Box>
          <Box display="flex" justifyContent="end" mt="32px">
            <ButtonLoader type="submit" loading={isLoading} width="205px">
              {t("employeeForm:acceptEmployeeData")}
            </ButtonLoader>
          </Box>
        </form>
      </FormProvider>
      <Snackbar
        isVisible={isError}
        text={t("validations:errorOccuredTryAgain")}
        severity="error"
      />
    </Box>
  )
}

export default AddEmployee
