export const employeeForm = {
  addEmployee: "Dodaj pracownika",
  inactiveEmployeeText: "Aby przesłać orzeczenie lub wystawić skierowanie należy najpierw aktywować pracownika",
  inactiveEmployee: "Pracownik nieaktywny",
  editEmployee: "Edytuj pracownika",
  personalData: "Dane osobowe",
  name: "Imię",
  enterName: "Wpisz imię",
  lastName: "Nazwisko",
  enterLastName: "Wpisz nazwisko",
  email: "E-mail",
  enterEmail: "Wpisz adres e-mail",
  phone: "Telefon",
  enterPhone: "Wpisz telefon",
  pesel: "PESEL",
  enterPesel: "Wpisz PESEL",
  dateOfBirth: "Data urodzenia",
  enterDateOfBirth: "Wpisz datę urodzenia",
  noPesel: "Brak numeru PESEL",
  position: "Stanowisko",
  enterPosition: "Wpisz stanowisko",
  identificationNumber: "Numer identyfikacyjny",
  enterIdentificationNumber: "Wpisz numer identyfikacyjny",
  homeAddress: "Adres zamieszkania",
  street: "Ulica",
  enterStreetAndHouseNumber: "Wpisz nazwę ulicy i numer budynku",
  postalCode: "Kod pocztowy",
  city: "Miasto",
  enterCity: "Wpisz miasto",
  acceptEmployeeData: "Akceptuj dane",
  saveEmployeeData: "Zapisz dane pracownika",
  assignBundle: "Przypisz pakiet",
  noBundle: "Brak pakietu",
  occupationalMedicine: "Medycyna pracy",
  occupationalMedicineValidUntil: "Medycyna pracy ważna do",
  occupationalMedicineOpinionPreview: "Podgląd orzeczenia medycyny pracy",
  occupationalMedicineReferralPreview: "Podgląd skierowania medycyny pracy",
  uploadOpinion: "Prześlij orzeczenie",
  createReferral: "Wystaw skierowanie",
  employeeUpdatedSuccessfully: "Udało się zedytować dane pracownika",
  fileNotChosen: "Nie wybrano pliku",
  attachmentAdded: "Dodano załącznik",
  changedExpirationDate: "Zmieniono datę ważności medycyny pracy",
  areYouSureYouWantToDeleteReferral: "Czy na pewno chcesz usunąć skierowanie?",
  fillEmployeeDataBeforeContinue: "Uzupełnij dane pracownika aby dodać dokumentacje.",
  verifyPatientData: "Zweryfikuj poprawność wprowadzonych danych pracownika i spróbuj ponownie.",
  areYouSureYouWantToDeleteMedicalReport: "Czy na pewno chcesz usunąć orzeczenie?",
  documentTypes: {
    medicalReport: "Orzeczenie",
    referral: "Skierowanie",
  },
  tableColumnTitle: {
    documentType: "Rodzaj dokumentu",
    createdAt: "Data dodania/wystawienia",
    preview: "Podgląd plików",
    validUntil: "Medycyna pracy ważna do"
  }
}
