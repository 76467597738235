import { defaultTranslations } from "./modules/defaultTranslations"
import { validations } from "./modules/validations"
import { header } from "./modules/header"
import { usersList } from "./modules/usersList"
import { page404 } from "./modules/page404"
import { employeeForm } from "./modules/employeeForm"
import { addReferral } from "./modules/addReferral"

const pl = {
  defaultTranslations,
  validations,
  header,
  usersList,
  page404,
  employeeForm,
  addReferral,
}

export default pl
