import { FC } from "react"
import { Box, MenuItem, Popover, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import useUtilsStyles from "../../../../../utils/utils.styles"
import { IPatientNoExternalData } from "../../../../../ts/interfaces/Patient"
import { RoutePath } from "../../../../../routes/Routes.types"
import { useDialog } from "../../../../../hooks/dialog/useDialog"
import LogsDialog from "./logsDialog/LogsDialog"
import {StyledTypography} from "./TablePopover.styles"

interface IProps {
  open: boolean
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  patient: IPatientNoExternalData
}

const TablePopover: FC<IProps> = ({ open, anchorEl, onClose, patient }) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    isOpened: isLogsDialogOpened,
    closeDialog: closeLogsDialog,
    openDialog: openLogsDialog,
  } = useDialog()

  const onEditClick = () => {
    navigate(`${RoutePath.EMPLOYEE_BY_ID.replace(":id", patient.id)}`)
  }

  const onLogsClick = () => {
    openLogsDialog()
  }

  const onReferralClick = () => {
    navigate(RoutePath.REFERRAL_ADD, { state: { employeeId: patient.id } })
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box minWidth="150px" py="8px">
          <MenuItem onClick={onEditClick}>
            <Box py="4px">
              <Typography
                variant="body2"
                className={utilClasses.textPrimaryMain}
              >
                {t("edition")}
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={onLogsClick}>
            <Box py="4px">
              <Typography
                variant="body2"
                className={utilClasses.textPrimaryMain}
              >
                {t("logs")}
              </Typography>
            </Box>
          </MenuItem>
          {patient.hasCompletePersonalData && (
            <MenuItem
              disabled={!patient.active}
              onClick={onReferralClick}
            >
              <Box py="4px">
                <StyledTypography
                  $isDisabled={!patient.active}
                  variant="body2"
                  className={utilClasses.textPrimaryMain}
                >
                  {t("referral")}
                </StyledTypography>
              </Box>
            </MenuItem>
          )}
        </Box>
      </Popover>
      <LogsDialog
        open={isLogsDialogOpened}
        onClose={closeLogsDialog}
        patientId={patient.id}
      />
    </>
  )
}

export default TablePopover
