import { Box, Button, Dialog, Typography } from "@material-ui/core"
import { FC, useEffect } from "react"
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form"
import TextField from "../../../common/field/textField/TextField"
import ButtonClose from "../../../common/button/buttonClose/ButtonClose"
import { yupResolver } from "@hookform/resolvers/yup"
import { addReferralTemplateValidationSchema } from "./AddReferralTemplateModal.validation"
import { useAddReferralTemplateMutation } from "../../../../api/apolloClient/mutation/addReferralTemplate/addReferralTemplate.hook"
import { useTranslation } from "react-i18next"

type AddReferralTemplateModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleAddReferral: (templateId?: string, update?: boolean) => void;
  refetchTemplates: () => void;
  employerId?: string | null;
}

export type AddReferralTemplateFormState = {
  createReferralTemplate: string;
}

const AddReferralTemplateModal: FC<AddReferralTemplateModalProps> = ({
  isOpen,
  handleClose,
  handleAddReferral,
  refetchTemplates,
  employerId,
}) => {
  const { t } = useTranslation()
  const referralsForm = useFormContext()
  const referralTemplateSelected = referralsForm.watch("referralTemplates")

  const referralTemplateForm = useForm<AddReferralTemplateFormState>({
    defaultValues: {
      createReferralTemplate: "",
    },
    mode: "all",
    resolver: yupResolver(addReferralTemplateValidationSchema),
  })

  const [addReferralTemplateAction, { loading }] = useAddReferralTemplateMutation()

  const submitReferralTempalte: SubmitHandler<AddReferralTemplateFormState> = async (payload) => {
    try {
      const { data } = await addReferralTemplateAction({ variables: { name: payload.createReferralTemplate, employerId: employerId ?? "" } })
      await handleAddReferral(data?.insertReferralTemplates.returning[0].id)
      refetchTemplates()
    } finally {
      handleClose()
      referralTemplateForm.reset()
    }
  }

  const submitUpdateReferralTempalte: SubmitHandler<AddReferralTemplateFormState> = async () => {
    try {
      await handleAddReferral(referralTemplateSelected.value, true)
      refetchTemplates()
    } finally {
      handleClose()
      referralTemplateForm.reset()
    }
  }

  useEffect(() => {
    referralTemplateForm.setValue("createReferralTemplate", referralTemplateSelected?.label ?? "")
  }, [referralTemplateSelected])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
    >
      <FormProvider {...referralTemplateForm}>
        <Box
          component="form"
          onSubmit={referralTemplateSelected ? referralTemplateForm.handleSubmit(submitUpdateReferralTempalte) : referralTemplateForm.handleSubmit(submitReferralTempalte)}
          display="flex"
          flexDirection="column"
          minWidth={700}
          px={18}
          py={6}
        >
          <Typography variant="h3">
            {referralTemplateSelected ? t("addReferral:referralTemplates:updateTemplate") : t("addReferral:referralTemplates:creatingTemplate")}
            </Typography>
          <Box my={3}>
            <TextField
              name="createReferralTemplate"
              label={t("addReferral:referralTemplates:typeTemplateName")}
              placeholder={t("addReferral:referralTemplates:typeName")}
            />
          </Box>
          <Box alignSelf="flex-end">
            <Button
              type="submit"
              disabled={loading}
            >
              {referralTemplateSelected ? t("addReferral:referralTemplates:updateTemplate") : t("addReferral:referralTemplates:creatingTemplate")}
            </Button>
          </Box>
        </Box>
        <Box
          position="absolute"
          top="10%"
          right="5%"
        >
          <ButtonClose onClick={handleClose} />
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default AddReferralTemplateModal
