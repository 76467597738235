import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

import PageContentLayout from "../../layout/pageContentLayout/PageContentLayout"
import Paper from "../../components/common/paper/Paper"
import useUtilsStyles from "../../utils/utils.styles"
import { RoutePath } from "../../routes/Routes.types"
import GoBackLink from "../../components/common/link/goBackLink/GoBackLink"

const Page404: FC = () => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()

  return (
    <PageContentLayout>
      <Paper>
        <Typography
          variant="h1"
          className={clsx(utilClasses.textPrimaryMain, utilClasses.fontBold)}
        >
          404
        </Typography>
        <Typography variant="h2">{t("page404:notFound")}</Typography>
        <Box mt="24px">
          <GoBackLink
            to={RoutePath.HOMEPAGE}
            text={t("page404:goBackToHomepage")}
          />
        </Box>
      </Paper>
    </PageContentLayout>
  )
}

export default Page404
