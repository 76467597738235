import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useFrameStyles = makeStyles((theme: Theme) =>
  createStyles({
    frame: {
      border: `solid 1px ${theme.palette.grey[200]}`,
      borderRadius: 6,
    },
  })
)

export default useFrameStyles
