export const typography = {
  fontFamily: ["Ping Regular", "Arial", "sans-serif"].join(),
  h1: {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "0.03em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.03em",
  },
  h3: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h4: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h5: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  body1: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body2: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  caption: {
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: undefined,
  },
}
