import { FC } from "react"
import { MenuItem as MenuItemMUI } from "@material-ui/core"

import { IProps } from "./MenuItem.types"
import useMenuItemStyles from "./MenuItem.styles"

const MenuItem: FC<IProps> = ({ onClick, children }) => {
  const classes = useMenuItemStyles()

  return (
    <MenuItemMUI
      disableGutters
      disableRipple={false}
      onClick={onClick}
      className={classes.root}
    >
      {children}
    </MenuItemMUI>
  )
}

export default MenuItem
