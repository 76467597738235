export const validations = {
  fieldIsRequired: "Pole jest wymagane",
  errorOccuredTryAgain: "Wystąpił błąd, spróbuj ponownie",
  invalidNationalIdentificationNumber: "Błędny PESEL",
  invalidEmailAddress: "Niepoprawny adres e-mail",
  errorOccured: "Wystąpił błąd",
  unknownError: "Nieznany błąd",
  failedToFetch: "Nie udało się pobrać danych",
  noResults: "Brak wyników",
  dateUntilMustBeAfterDateSince: "Data 'Do' musi być po dacie 'Od'",
  cannotPrintFile: "Nie udało się wydrukować pliku",
  cannotDownloadFile: "Nie udało się pobrać pliku",
  cannotDeleteFile: "Nie udało się usunąć pliku",
  enterMax20Chars: "Wpisz maksymalnie 20 znaków",
  valueMustBeNumber: "Wartość musi być liczbą",
  fillInMissingChars: "Uzupełnij brakujące znaki",
  enterValidEmail: "Wpisz poprawny adres e-mail",
  enterValidUrl: "Wpisz poprawny adres www",
  enterMaxChars_one: "Wpisz maksymalnie {{count}} znak",
  enterMaxChars_few: "Wpisz maksymalnie {{count}} znaki",
  enterMaxChars_many: "Wpisz maksymalnie {{count}} znaków",
  enterMinChars_one: "Wpisz co najmniej {{count}} znak",
  enterMinChars_few: "Wpisz co najmniej {{count}} znaki",
  enterMinChars_many: "Wpisz co najmniej {{count}} znaków",
  maxNumberIs: "Maksymalna liczba wynosi {{count}}",
  minNumberIs: "Minimalna liczba wynosi {{count}}",
  openingHourMustBeBeforeClosing:
    "Godzina otwarcia musi być wcześniejsza niż godzina zamknięcia",
  endDateMustBeAfterStartDate: "Data zakończenia musi być po dacie rozpoczęcia",
  enterInteger: "Wpisz liczbę całkowitą",
  enterNegativeNumber: "Wpisz liczbę ujemną",
  enterPositiveNumber: "Wpisz liczbę dodatnią",
  enterNotNegativeNumber: "Wpisz liczbę >= 0",
  enterPositiveNumberWithTwoDecimalPlaces:
    "Wpisz liczbę dodatnią z maksymalnie dwoma miejscami po przecinku",
  enterValidDate: "Wprowadź poprawną datę",
  enterPhone: "Wprowadź prawidłowy numer telefonu. Numer powinien zawierać nr kierunkowy (prefix) oraz nie powinien zawierać spacji. Np +48512345678",
}
