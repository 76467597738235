import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

const useUtilsStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTextSecondary: {
      color: theme.palette.text.secondary,
    },
    textSecondaryDark: {
      color: theme.palette.secondary.dark,
    },
    textPrimaryMain: {
      color: theme.palette.primary.main,
    },
    textErrorMain: {
      color: theme.palette.error.main,
    },
    textWhite: {
      color: theme.palette.common.white,
    },
    textGrey300: {
      color: theme.palette.grey[300],
    },
    textGrey400: {
      color: theme.palette.grey[400],
    },
    fontNormal: {
      fontWeight: 400,
    },
    fontMedium: {
      fontWeight: 500,
    },
    fontSemibold: {
      fontWeight: 600,
    },
    fontBold: {
      fontWeight: 700,
    },
    cursorPointer: {
      cursor: "pointer",
    },
    gap4: {
      gap: "4px",
    },
    gap8: {
      gap: "8px",
    },
    gap16: {
      gap: "16px",
    },
    gap24: {
      gap: "24px",
    },
    gap32: {
      gap: "32px",
    },
    shrink0: {
      flexShrink: 0,
    },
    displayBlock: {
      display: "block",
    },
    displayNone: {
      display: "none",
    },
    textUnderline: {
      textDecoration: "underline",
    },
    lineHeight24: {
      lineHeight: "24px",
    },
  })
)

export default useUtilsStyles
