import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"

import useStepIndicatorStyles from "./StepIndicator.styles"
import useUtilsStyles from "../../../../../utils/utils.styles"

interface IProps {
  stepNumber: number
  text: string
  active: boolean
}

const StepIndicator: FC<IProps> = ({ stepNumber, text, active }) => {
  const classes = useStepIndicatorStyles()
  const utilClasses = useUtilsStyles()

  return (
    <Box display="flex" alignItems="center" className={utilClasses.gap4}>
      <Box className={clsx(classes.indicator, !active && classes.disabledBg)}>
        {stepNumber}
      </Box>
      <Typography
        className={clsx(!active && classes.disabledText)}
        variant="body2"
      >
        {text}
      </Typography>
    </Box>
  )
}

export default StepIndicator
