import { FC } from "react"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconDownload } from "../../../../../../assets/svg/printer.svg"
import useUtilsStyles from "../../../../../../utils/utils.styles"
import { Endpoint } from "../../../../../../api/endpoints.types"
import Snackbar from "../../../../../common/snackbar/Snackbar"
import { useQueryPrint } from "../../../../../../api/reactQuery/queries/print"

interface IProps {
  id: string;
}

export const PrintReferral: FC<IProps> = ({ id }) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()

  const {
    isError: isErrorPrint,
    refetch: print,
  } = useQueryPrint(
    id,
    "application/pdf",
    `${Endpoint.REFERRALS}/${id}/download`,
    "referrals",
    false
  )

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        flexBasis="220px"
        display="flex"
        className={utilClasses.gap16}
      >
        <Box
          onClick={() => print()}
          className={utilClasses.cursorPointer}
        >
          <IconDownload />
        </Box>
      </Box>
      <Snackbar
        isVisible={isErrorPrint}
        text={t("validations:cannotDeleteFile")}
        severity="error"
      />
    </Box>
  )
}