export const colors: Record<string, string> = {
  telemediGreen: "#20A869",
  telemediGreenLight: "#AFE0C9", // button disabled
  telemediGreenDark: "#0E7E4A",
  telemediGreenAccent10: "#EAF8F1",
  telemediGreenAccent50: "#96DCBB",
  telemediGreenAccent100: "#00C377",

  telemediBlue: "#004EC3",
  telemediBlueDark: "#0443A1",
  telemediBlueDarker: "#0D408E",
  telemediBlueGradient:
    "linear-gradient(277.22deg, #0C408E 6.22%, #012760 94.16%)",
  telemediBlueAccent10: "#EEF2F9",
  telemediBlueAccent20: "#CBDDF1",
  telemediBlueAccent60: "#083780",
  telemediBlueAccent70: "#012760",

  telemediTextPrimary: "#2D2D2D",
  telemediTextSecondary: "#3C3F3D",
  telemediTextDisabled: "#BBBCBB",

  telemediAppBarBg: "#EFEDE9",
  telemediPageBackground: "#F6F5F2",
  telemediBackground: "#FFFFFF",
  telemediTabBackground: "#EBEBEB",

  telemediGray100: "#EAEAEA", // input border disabled
  telemediGray200: "#C4C4C4", // input border
  telemediGray300: "#787878",
  telemediGray400: "#757575",
  telemediGray500: "#3C3F3D",
  telemediGray700: "#242424",
  telemediGray900: "#141414",

  telemediRed: "#B93C3C",

  telemediWhite: "#FFFFFF",
  telemediBlack: "#000000",
}
