import { ReactComponent as IconChevronDown } from "../../../assets/svg/chevron-down.svg"

const MuiSelect = {
  IconComponent: IconChevronDown,
}

const MuiTextField = {
  SelectProps: {
    IconComponent: IconChevronDown,
  },
}

export const select = {
  MuiSelect,
  MuiTextField,
}
