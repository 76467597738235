import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useButtonCloseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      border: "none",
      cursor: "pointer",
    },
  })
)

export default useButtonCloseStyles
