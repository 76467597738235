import { createTheme } from "@material-ui/core"
import { palette } from "./palette"
import { typography } from "./typography"
import { overrides } from "./overrides/overrides"
import { mixins } from "./mixins"
import { breakpoints } from "./breakpoints"
import { props } from "./props/props"

const theme = createTheme({
  palette,
  mixins,
  typography,
  breakpoints,
  props,
  overrides,
})

export default theme
