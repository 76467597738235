import { FC, useEffect, useState } from "react"
import { Snackbar as SnackbarMUI } from "@material-ui/core"
import { Alert, Color } from "@material-ui/lab"

interface IProps {
  isVisible: boolean
  text: string
  autoHideDuration?: number
  severity?: Color
}

const Snackbar: FC<IProps> = ({
  isVisible,
  text,
  autoHideDuration = 3000,
  severity = "success",
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (isVisible) {
      setIsOpen(true)
    }
  }, [isVisible])

  return (
    <SnackbarMUI
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {text}
      </Alert>
    </SnackbarMUI>
  )
}

export default Snackbar
