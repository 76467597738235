import { FC } from "react"
import { Box, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import Frame from "../frame/Frame"
import FrameCell from "../frame/frameCell/FrameCell"
import { jobTypes, referralTypes } from "../formFields/FormFields.config"
import { IInputOption } from "../../../../ts/interfaces/FormField"
import { format } from "date-fns"
import { ReferralTypes } from "../formFields/FormFields.enum"

const FilledData: FC = () => {
  const { t } = useTranslation()
  const { getValues } = useFormContext()

  const chosenReferral = referralTypes.find((type) => type.value === getValues("referralType"))
  const isEpidemicReferralType = chosenReferral?.value === ReferralTypes.EPIDEMIC

  const chosenJobType = jobTypes.find(
    (type) => type.value === getValues("jobType")
  )

  return (
    <Box>
      <Box mb="48px">
        <FrameCell
          widthLabel={230}
          label={t("addReferral:referralType")}
          value={chosenReferral?.label || "-"}
        />
      </Box>
      <Box mb="48px">
        {
          isEpidemicReferralType
            ? (
              <FrameCell
                widthLabel={230}
                label={t("addReferral:rangeActivitiesPossibilityTransmittingInfection")}
                value={getValues("rangeActivitiesPossibilityTransmittingInfection") || "-"}
              />
            )
            : (
              <FrameCell
                widthLabel={230}
                label={t("addReferral:jobTitleDescription")}
                value={getValues("jobTitleDescription") || "-"}
              />
            )
        }
      </Box>
      <Box mb="48px">
        <FrameCell
          widthLabel={230}
          label={t("addReferral:extraRemarksLabel")}
          value={getValues("extraRemarks") || "-"}
        />
      </Box>
      <Box>
        {
          isEpidemicReferralType
            ? (
              <FrameCell
                widthLabel={230}
                label={t("addReferral:typeOfJob")}
                value={chosenJobType?.label || "-"}
              />
            )
            : (
              <Frame title={t("addReferral:choosenExposuresFactors")}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FrameCell
                      widthLabel={160}
                      label={t("addReferral:physicalFactors")}
                      value={
                        getValues("physicalFactors")
                          .map((el: IInputOption) => el.label)
                          .join(", ") || "-"
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FrameCell
                      widthLabel={160}
                      label={t("addReferral:dusts")}
                      value={
                        getValues("dusts")
                          .map((el: IInputOption) => el.label)
                          .join(", ") || "-"
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FrameCell
                      widthLabel={160}
                      label={t("addReferral:chemicalFactors")}
                      value={
                        getValues("chemicalFactors")
                          .map((el: IInputOption) => el.label)
                          .join(", ") || "-"
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FrameCell
                      widthLabel={160}
                      label={t("addReferral:biologicalFactors")}
                      value={
                        getValues("biologicalFactors")
                          .map((el: IInputOption) => el.label)
                          .join(", ") || "-"
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FrameCell
                      widthLabel={160}
                      label={t("addReferral:otherFactorsIncludingDangerous")}
                      value={
                        getValues("otherFactors")
                          .map((el: IInputOption) => el.label)
                          .join(", ") || "-"
                      }
                    />
                  </Grid>
                </Grid>
              </Frame>
            )
        }
        <Box mt="48px">
          <FrameCell
            widthLabel={230}
            label={t("addReferral:referralDeadlineDate")}
            value={
              getValues("referralDeadlineDate")
                ? format(getValues("referralDeadlineDate"), "yyyy-MM-dd")
                : "-"
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

export default FilledData
