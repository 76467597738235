import { makeStyles, createStyles, Theme } from "@material-ui/core"

const useMenuItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 8,
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.accent100,
      },
    },
  })
)

export default useMenuItemStyles
