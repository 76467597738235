import * as yup from "yup"

import i18n from "../../../i18n/i18n"
import { requiredDateSchema } from "../../../utils/yup"
import { AddReferralSubmitData } from "./AddReferral.types"

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const MAX_JOB_TITLE_DESCRIPTION_CHARS = 2000
export const MAX_INFECTION_POSSIBILITY_FIELD_CHARS = 1000
export const EXTRA_MARKS_MAX_FIELD_CHARACTERS = 2000

export const addReferralsValidationSchema: yup.SchemaOf<AddReferralSubmitData> = yup.object().shape({
  jobTitleDescription: yup
    .string()
    .required(i18n.t("validations:fieldIsRequired"))
    .max(MAX_JOB_TITLE_DESCRIPTION_CHARS, i18n.t("validations:enterMaxChars_many", { count: MAX_JOB_TITLE_DESCRIPTION_CHARS })),
  rangeActivitiesPossibilityTransmittingInfection: yup
    .string()
    .trim()
    .required(i18n.t("validations:fieldIsRequired"))
    .max(MAX_INFECTION_POSSIBILITY_FIELD_CHARS, i18n.t("validations:enterMaxChars_many", { count: MAX_INFECTION_POSSIBILITY_FIELD_CHARS })),
  jobType: yup
    .string()
    .required(i18n.t("validations:fieldIsRequired")),
  referralDeadlineDate: requiredDateSchema,
  extraRemarks: yup
    .string()
    .trim()
    .max(EXTRA_MARKS_MAX_FIELD_CHARACTERS, i18n.t("validations:enterMaxChars_many", { count: EXTRA_MARKS_MAX_FIELD_CHARACTERS })),
}).required().when((values: string, schema: schemaType) => {
  for (const key in schema.fields) {
    const currentField = document.getElementById(key)

    if (currentField === null) {
      delete schema.fields[key]
    }
  }
})
