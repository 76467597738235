import { FC } from "react"
import { Box } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import PageContentLayout from "../../../layout/pageContentLayout/PageContentLayout"
import Paper from "../../../components/common/paper/Paper"
import GoBackLink from "../../../components/common/link/goBackLink/GoBackLink"
import EditEmployee from "../../../components/form/editEmployee/EditEmployee"
import OccupationalMedicine from "../../../components/module/occupationalMedicine/OccupationalMedicine"
import { useQueryPatient } from "../../../api/reactQuery/queries/patients"
import StatusText from "../../../components/common/statusText/StatusText"
import useUtilsStyles from "../../../utils/utils.styles"

const EmployeeByIdPage: FC = () => {
  const { t } = useTranslation()
  const params = useParams()
  const employeeId = params.id || ""
  const utilClasses = useUtilsStyles()

  const { data, isLoading, isError } = useQueryPatient(employeeId)

  return (
    <PageContentLayout>
      <Paper>
        <GoBackLink text={t("goBackToEmployeesList")} />
        <Box mt="24px">
          {data && (
            <>
              <EditEmployee patientData={data} patientId={employeeId} />
              <OccupationalMedicine
                patient={data}
                referrals={data.referrals}
                medicalReports={data.medicalReports}
              />
            </>
          )}
          {isLoading && (
            <Box mb="24px">
              <Skeleton width="100%" variant="rect" height="800px" />
            </Box>
          )}
          {isError && (
            <Box className={utilClasses.textErrorMain}>
              <StatusText text={t("validations:failedToFetch")} />
            </Box>
          )}
        </Box>
      </Paper>
    </PageContentLayout>
  )
}

export default EmployeeByIdPage
