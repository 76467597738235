import i18n, { Resource } from "i18next"
import { initReactI18next } from "react-i18next"
import pl from "./pl/pl"

// i18n resources
const resources: Resource = {
  pl,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "defaultTranslations",
    lng: "pl",
    keySeparator: false,
  })
  .then()

export default i18n
