import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {XTENANT} from "../../api/api.config"

const initialState = {
  clinicId: XTENANT ?? "8f464df1-f399-4ac8-ad67-655342b53410", // default staging clinicId fallback - todo: move to ci.yml (GH token needed)
}

export const clinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    setClinicId: (state, action: PayloadAction<string>) => {
      state.clinicId = action.payload
    },
  },
})

export const { setClinicId } = clinicSlice.actions

export default clinicSlice.reducer
