import { useMutation, useQueryClient } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import { IReferralResponseObject } from "../../../ts/interfaces/Referral"

interface IPostArgs {
  employerId?: string;
  referralId?: string;
  patientId?: string
  jobTitleDescription?: string
  exposureIds: string[]
  type: string
  referralDeadlineDate: string | null
  jobType?: number
  rangeActivitiesPossibilityTransmittingInfection?: string
  extraRemarks: string
  templateId?: string
}

export const useMutationPostReferral = () => {
  const queryClient = useQueryClient()

  return useMutation<IReferralResponseObject, AxiosError, IPostArgs>(
    async ({
      patientId,
      jobTitleDescription,
      exposureIds,
      type,
      referralDeadlineDate,
      jobType,
      rangeActivitiesPossibilityTransmittingInfection,
      extraRemarks,
      templateId,
      employerId,
    }) => {
      const response = await axios.post(Endpoint.REFERRALS, {
        patientId,
        jobTitleDescription,
        exposureIds,
        type,
        referralDeadlineDate,
        jobType,
        rangeActivitiesPossibilityTransmittingInfection,
        extraRemarks,
        templateId,
        employerId
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationUpdateReferral = () => {
  const queryClient = useQueryClient()

  return useMutation<IReferralResponseObject, AxiosError, IPostArgs>(
    async ({
      referralId,
      patientId,
      jobTitleDescription,
      exposureIds,
      type,
      referralDeadlineDate,
      jobType,
      rangeActivitiesPossibilityTransmittingInfection,
      templateId,
    }) => {
      const response = await axios.put(`${Endpoint.REFERRALS}/${referralId}`, {
        patientId,
        jobTitleDescription,
        exposureIds,
        type,
        referralDeadlineDate,
        jobType,
        rangeActivitiesPossibilityTransmittingInfection,
        templateId,
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationDeleteReferral = () => {
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError, string>(
    async (id) => {
      await axios.delete(`${Endpoint.REFERRALS}/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationDeleteReferralTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError, string>(
    async (id) => {
      await axios.delete(`${Endpoint.REFERRALS_TEMPLATES}/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}
