import { FC } from "react"

import { MenuItem, TextField } from "@material-ui/core"
import get from "lodash.get"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { IProps } from "./SelectField.types"
import FieldLabel from "../fieldLabel/FieldLabel"

const SelectField: FC<IProps> = ({
  name,
  label,
  error,
  errorMessage,
  disabled = false,
  placeholder,
  variant = "outlined",
  options,
}) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const fieldError = get(errors, name, false)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {!!label && <FieldLabel>{label}</FieldLabel>}
          <TextField
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            select
            disabled={disabled}
            fullWidth
            variant={variant}
            error={error || !!fieldError}
            helperText={
              errorMessage ||
              (fieldError.message && typeof fieldError.message === "string"
                ? t(fieldError.message)
                : t(fieldError.message?.key, fieldError.message?.values))
            }
            placeholder={placeholder}
          >
            {options.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    />
  )
}

export default SelectField
