import {
  LogAction,
  LogNewValues,
  LogSubject,
} from "../../../../../../../ts/types/Log"
import i18n from "../../../../../../../i18n/i18n"
import { format } from "date-fns"

export const getActionMessage = (
  action: LogAction,
  subject: LogSubject,
  newValues: LogNewValues
  // newValues variable from API is very weird, it can be an empty
  // array or an object with various properties with different combinations
  // so the variable is tested
  // in every switch case, to find out what the variable contains
  // and to make sure the app won't crash
) => {
  let bundleName
  let since
  let until

  switch (true) {
    case action === "create" && subject === "patient":
      // on the patient create we only need to return a message that the patient was added
      return i18n.t("usersList:employeeAdded")
    case action === "update" && subject === "patient": {
      if (typeof newValues === "object" && "active" in newValues) {
        return newValues.active
          ? i18n.t("usersList:activateEmployee")
          : i18n.t("usersList:inactivateEmployee")
      }
      // on the patient update we only need to return a message that the patient was edited
      return i18n.t("usersList:employeeDataEdited")
    }
    case action === "create" && subject === "bundle_link":
      // on the bundle_link create we need to get the data what bundle
      // was chosen and the time range when the bundle is active
      if (typeof newValues === "object") {
        if ("bundle" in newValues) {
          bundleName = newValues.bundle?.name
        }
        if (
          "validFrom" in newValues &&
          typeof newValues.validFrom === "string"
        ) {
          since = format(new Date(newValues.validFrom), "yyyy-MM-dd")
        }
        if ("validTo" in newValues && typeof newValues.validTo === "string") {
          until = format(new Date(newValues.validTo), "yyyy-MM-dd")
        }
      }
      return i18n.t("usersList:bundleAssigned", {
        bundleName,
        since,
        until,
      })
    case action === "update" && subject === "bundle_link":
      // on the bundle_link update we need to find out wheter the new bundle was chosen
      // or not, if yes we need to display the new bundle name and its time range, if not
      // we need to show only its new time range
      if (typeof newValues === "object") {
        if ("bundle" in newValues) {
          bundleName = newValues.bundle?.name
        }
        if (
          "validFrom" in newValues &&
          typeof newValues.validFrom === "string"
        ) {
          since = format(new Date(newValues.validFrom), "yyyy-MM-dd")
        }
        if ("validTo" in newValues && typeof newValues.validTo === "string") {
          until = format(new Date(newValues.validTo), "yyyy-MM-dd")
        }
      }
      return bundleName
        ? i18n.t("usersList:bundleEdited", {
            bundleName,
            since,
            until,
          })
        : i18n.t("usersList:bundleTimeRangeEdited", {
            since,
            until,
          })
    case action === "remove" && subject === "bundle_link":
      // on the bundle_link create we only need to return a message that the bundle_link was removed
      return i18n.t("usersList:bundleRemoved")
    case action === "create" && subject === "referral":
      // on the referral create we only need to return a message that the referral was created
      return i18n.t("usersList:referralCreated")
    case action === "remove" && subject === "referral":
      // on the referral remove we only need to return a message that the referral was removed
      return i18n.t("usersList:referralRemoved")
    case action === "create" && subject === "medical_report":
      // on the referral create we need to return a message that the referral was created and display its valid date
      if (typeof newValues === "object") {
        if (
          "validUntil" in newValues &&
          typeof newValues.validUntil === "string"
        ) {
          until = format(new Date(newValues.validUntil), "yyyy-MM-dd")
        }
      }
      return i18n.t("usersList:medicalOpinionAttached", { until })
    case action === "update" && subject === "medical_report":
      // on the referral update we need to return a message that the referral was updated and display its new valid date
      if (typeof newValues === "object") {
        if (
          "validUntil" in newValues &&
          typeof newValues.validUntil === "string"
        ) {
          until = format(new Date(newValues.validUntil), "yyyy-MM-dd")
        }
      }
      return i18n.t("usersList:medicalOpinionEdited", { until })
    default:
      // default unknown log
      return i18n.t("usersList:unknownLog")
  }
}
