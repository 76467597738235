import {useQuery} from "@apollo/client"
import {HR_USER_QUERY} from "./hrUser.gql"
import {HrUserQueryResponse, HrUserQueryVariables} from "./hrUser.types"
import {QueryHookOptions} from "@apollo/client/react/types/types"

const useHrUserQuery = (options: QueryHookOptions<HrUserQueryResponse, HrUserQueryVariables>) =>
  useQuery<HrUserQueryResponse, HrUserQueryVariables>(HR_USER_QUERY, options)

export {
  useHrUserQuery
}
