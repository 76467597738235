import { buttons } from "./buttons"
import { selects } from "./selects"
import { labels } from "./labels"
import { inputs } from "./inputs"
import { skeletons } from "./skeletons"
import { paginations } from "./paginations"
import { chip } from "./chip"
import { alert } from "./alert"

export const overrides = {
  ...buttons,
  ...selects,
  ...labels,
  ...inputs,
  ...skeletons,
  ...paginations,
  ...chip,
  ...alert,
}
