import {useHrUserEmployersQuery} from "../api/apolloClient/query/hrUserEmployers/hrUserEmployers.hook"
import {useAppDispatch, useAppSelector} from "./storeHooks/storeHooks"
import {setShouldShowEmployerFields} from "../store/user/user.slice"

export const useGetHrUserEmployer = () => {
  const user = useAppSelector(state => state.user.data)
  const dispatch = useAppDispatch()

  return useHrUserEmployersQuery({
    variables: {
      id: user?.token?.sub ?? ""
    },
    onCompleted: data => {
      if (data.hrUserEmployers.edges.length > 1) {
        dispatch(setShouldShowEmployerFields(true))
      }
    }
  })
}
