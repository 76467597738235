import { useQuery, UseQueryOptions } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import { IEmployerListing } from "../../../ts/interfaces/Employer"

export const useQueryEmployers = (
  options?: UseQueryOptions<
    IEmployerListing,
    AxiosError,
    IEmployerListing
  >
) => {
  return useQuery<IEmployerListing, AxiosError>(
    ["employers"],
    async () => {
      const res = await axios(Endpoint.EMPLOYERS, { params: { itemsPerPage: 200 } })
      return res.data
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}
