import { makeStyles, createStyles, Theme } from "@material-ui/core"

interface IStyleProps {
  isOpened: boolean
}

const useFiltersFormStyles = makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    filterIcon: {
      transform: ({ isOpened }) =>
        isOpened ? "rotate(-90deg)" : "rotate(0deg)",
      transition: "transform .3s",
    },
    filtersBox: {
      maxHeight: ({ isOpened }) => (isOpened ? "400px" : "0px"),
      transition: "max-height .3s ease-in-out",
      overflow: "hidden",
    },
  })
)

export default useFiltersFormStyles
