import i18n from "../../../../i18n/i18n"

export const tableHeaders = [
  {
    id: 1,
    text: i18n.t("usersList:nameAndLastName"),
    width: "200px",
  },
  {
    id: 11,
    text: i18n.t("usersList:employeeStatus"),
    width: "200px",
  },
  {
    id: 2,
    text: i18n.t("usersList:employer"),
    width: "160px",
    forAllClinicsAdmin: true,
  },
  {
    id: 3,
    text: i18n.t("usersList:identificationNumber"),
    width: "160px",
  },
  // https://telemedico.atlassian.net/browse/LESS-3135
  // Hide fields because they are not used
  // {
  //   id: 4,
  //   text: i18n.t("usersList:bundle"),
  //   width: "120px",
  // },
  // {
  //   id: 5,
  //   text: i18n.t("usersList:status"),
  //   width: "120px",
  // },
  // {
  //   id: 6,
  //   text: i18n.t("usersList:activeSince"),
  //   width: "120px",
  // },
  // {
  //   id: 7,
  //   text: i18n.t("usersList:activeUntil"),
  //   width: "120px",
  // },
  {
    id: 8,
    text: i18n.t("usersList:occupationalMedicine"),
    width: "160px",
  },
  {
    id: 9,
    text: i18n.t("usersList:validUntil"),
    width: "120px",
  },
  {
    id: 10,
    text: i18n.t("usersList:actions"),
    width: "50px",
  },
]
