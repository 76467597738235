import { palette } from "../palette"

const MuiInputLabel = {
  outlined: {
    transform: "translate(20px, 12px)",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    backgroundColor: palette.common.white,
    padding: "0 4px",
  },
}

export const labels = {
  MuiInputLabel,
}
