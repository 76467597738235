import { useQuery } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import {
  IPatientsListing,
  IPatient,
  IPatientNoExternalData,
} from "../../../ts/interfaces/Patient"
import {EmployeeStatus} from "../../../ts/enums/EmployeeStatus"

export interface IParamsGetPatients {
  page?: number
  employer?: string
  firstName?: string
  lastName?: string
  idNumber?: string
  bundle?: string
  status?: EmployeeStatus | ""
  activeSince?: string
  activeUntil?: string
  validSince?: string
  validUntil?: string
  occupationalMedicineStatus?: string
  overdueReports?: boolean
}

// there is an issue in Api platform which nesting response data to 0 property
// when occupationalMedicineStatus is provided, so we need to handle both scenarios
export interface IPatientNoExternalDataWith0 {
  0: IPatientNoExternalData
}

export const useQueryPatients = (params: IParamsGetPatients) => {
  return useQuery<IPatientsListing, AxiosError>(
    ["patients", JSON.stringify(params)],
    async () => {
      const res = await axios(Endpoint.PATIENTS, {
        params: {
          page: params.page ?? undefined,
          ["employer.id"]: params.employer || undefined,
          firstName: params.firstName || undefined,
          lastName: params.lastName || undefined,
          identificationNumber: params.idNumber || undefined,
          ["activeBundleInfo.bundle.name"]: params.bundle || undefined,
          ["active"]:
            params.status === EmployeeStatus.ACTIVE
              ? 1
              : params.status === EmployeeStatus.INACTIVE
              ? 0
              : undefined,
          ["medicalReports.validUntil[after]"]: params.validSince || undefined,
          ["medicalReports.validUntil[before]"]: params.validUntil || undefined,
          ["activeBundleInfo.validFrom[after]"]:
            params.activeSince || undefined,
          ["activeBundleInfo.validTo[before]"]: params.activeUntil || undefined,
          ["med_status_filter_search"]:
            params.occupationalMedicineStatus || undefined,
          ["overdue_med_reports_search"]: params.overdueReports || undefined,
        },
      })

      const transformedData = res.data.data.map(
        (record: IPatientNoExternalData | IPatientNoExternalDataWith0) =>
          "0" in record ? record["0"] : record
      )

      res.data.data = transformedData

      return res.data
    },
    {
      staleTime: 20 * 1000,
    }
  )
}

export const useQueryPatient = (id: string) => {
  return useQuery<IPatient, AxiosError>(
    ["patients", id],
    async () => {
      const res = await axios(`${Endpoint.PATIENTS}/${id}`)
      return res.data
    },
    {
      staleTime: 120 * 1000,
    }
  )
}
