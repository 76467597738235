import { Box, Button, Dialog, Typography } from "@material-ui/core"
import { FC } from "react"
import { ReactComponent as IconWarning } from "../../../../assets/svg/warning.svg"
import useUtilsStyles from "../../../../utils/utils.styles"
import ButtonClose from "../../../common/button/buttonClose/ButtonClose"
import { IInputOption } from "../../../../ts/interfaces/FormField"
import { useDeleteReferralTemplateMutation } from "../../../../api/apolloClient/mutation/deleteReferralTemplate/deleteReferralTemplate.hook"
import { useFormContext } from "react-hook-form"
import { useMutationDeleteReferralTemplate } from "../../../../api/reactQuery/mutations/referrals"
import { useTranslation } from "react-i18next"

type DeleteReferralTemplateModalProps = {
  open: boolean;
  closeModal: () => void;
  selectedOption?: IInputOption;
  refetchTemplates: () => void;
  setIsReferralTemplateDeletedSuccessfully: (state: boolean) => void;
  setIsReferralTemplateDeleteError: (state: boolean) => void;
}

const DeleteReferralTemplateModal: FC<DeleteReferralTemplateModalProps> = ({
  open,
  closeModal,
  selectedOption,
  refetchTemplates,
  setIsReferralTemplateDeletedSuccessfully,
  setIsReferralTemplateDeleteError,
}) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()
  const { setValue } = useFormContext()
  const [deleteReferralTemplateAction, { error }] = useDeleteReferralTemplateMutation()
  const {
    mutate: deleteReferral,
    isError: isDeleteReferralError,
  } = useMutationDeleteReferralTemplate()

  const handleDeleteReferralTemplate = async () => {
    if(!selectedOption) {
      return
    }

    try {
      await deleteReferralTemplateAction({
        variables: {
          id: selectedOption.value as string
        }
      })
      await deleteReferral(selectedOption.value as string)
      await refetchTemplates()
      setValue("referralTemplates", null)
      setIsReferralTemplateDeletedSuccessfully(true)
    } catch(err) {
      if(err || isDeleteReferralError || error) {
        setIsReferralTemplateDeleteError(true)
      }
    } finally {
      closeModal()
      setIsReferralTemplateDeletedSuccessfully(false)
      setIsReferralTemplateDeleteError(false)
    }
  }

  return (
    <Dialog
      open={open}
    >
      <Box
        display="flex"
        flexDirection="column"
        px={8}
        py={6}
      >
        <Box
          display="flex"
          justifyContent="center"
          my="32px"
          className={utilClasses.textErrorMain}
        >
          <IconWarning />
        </Box>
        <Typography variant="h4" align="center">{t("addReferral:referralTemplates:deleteConfirm")} - {selectedOption?.label}?</Typography>
        <Box
          display="flex"
          justifyContent="center"
          gridGap={24}
          mt={4}
        >
          <Button onClick={closeModal} variant="outlined">{t("addReferral:referralTemplates:noIDontWant")}</Button>
          <Button onClick={handleDeleteReferralTemplate}>{t("addReferral:referralTemplates:yesIWant")}</Button>
        </Box>
      </Box>
      <Box
        position="absolute"
        top="10%"
        right="5%"
      >
        <ButtonClose onClick={closeModal} />
      </Box>
    </Dialog>
  )
}

export default DeleteReferralTemplateModal
