const MuiSelect = {
  root: {
    padding: "10px 20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    borderRadius: "8px",
  },
  outlined: {
    borderRadius: "8px",
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: "8px",
    },
  },
  iconOutlined: {
    right: "12px",
  },
}

export const selects = {
  MuiSelect,
}
