import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { scrollToTop } from "../../utils/scroll"

const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop("auto")
  }, [pathname])
}

export default useScrollToTop
