import { FC } from "react"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconTrash } from "../../../../../assets/svg/trash.svg"
import useUtilsStyles from "../../../../../utils/utils.styles"
import Snackbar from "../../../../common/snackbar/Snackbar"
import DialogConfirmation from "../../../../common/dialog/dialogConfirmation/DialogConfirmation"
import { useDialog } from "../../../../../hooks/dialog/useDialog"

interface IProps {
  label: string;
  onSubmit: () => void;
  isLoading: boolean;
  isError: boolean;
}

export const DeleteDocument: FC<IProps> = ({
  label,
  onSubmit,
  isLoading,
  isError
}) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()
  const { isOpened, closeDialog, openDialog } = useDialog()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        flexBasis="220px"
        display="flex"
        className={utilClasses.gap16}
      >
        <Box
          onClick={openDialog} 
          className={utilClasses.cursorPointer}
        >
          <IconTrash />
        </Box>
      </Box>
      <DialogConfirmation
        open={isOpened}
        onClose={closeDialog}
        onActionButtonClick={onSubmit}
        title={label}
        variant="warning"
        actionButtonText={t("yes")}
        actionButtonLoading={isLoading}
      />
      <Snackbar
        isVisible={isError}
        text={t("validations:cannotDeleteFile")}
        severity="error"
      />
    </Box>
  )
}