import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconArrowBack } from "../../../../assets/svg/arrow-left.svg"
import useUtilsStyles from "../../../../utils/utils.styles"

interface IProps {
  to?: string
  text?: string
}

const GoBackLink: FC<IProps> = ({ to, text }) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = () => {
    return to ? navigate(to) : navigate(-1)
  }

  return (
    <Box width="max-content">
      <Box
        onClick={goBack}
        display="flex"
        alignItems="center"
        className={utilClasses.gap16}
      >
        <IconArrowBack width="24px" height="24px" />
        <Typography
          variant="body1"
          className={clsx(
            utilClasses.textUnderline,
            utilClasses.fontBold,
            utilClasses.cursorPointer
          )}
        >
          {text ? text : t("goBack")}
        </Typography>
      </Box>
    </Box>
  )
}

export default GoBackLink
