export const usersList = {
  addEmployee: "Dodaj pracownika",
  nameAndLastName: "Imię i nazwisko",
  bundle: "Pakiet",
  status: "Status",
  activeSince: "Aktywny od",
  activeUntil: "Aktywny do",
  validSince: "Ważne od",
  validUntil: "Ważne do",
  employeeStatus: "Status pracownika",
  activeEmployee: "Aktywny",
  inactiveEmployee: "Nieaktywny",
  activateEmployee: "Aktywowano pracownika",
  inactivateEmployee: "Dezaktywowano pracownika",
  identificationNumber: "Numer identyfikacyjny",
  occupationalMedicine: "Medycyna pracy",
  actions: "Akcje",
  medicalOpinion: "Orzeczenie",
  createReferral: "Wystaw skierowanie",
  referralDetails: "Szczegóły skierowania",
  employeeLogs: "Logi dotyczące pracownika",
  noLogs: "Brak logów",
  date: "Data",
  responsiblePerson: "Os. odpowiedzialna",
  action: "Akcja",
  unknownLog: "Nieznany log",
  employeeAdded: "Dodano pracownika",
  employeeDataEdited: "Edycja danych pracownika",
  bundleAssigned:
    "Przypisanie pakietu ''{{bundleName}}'' od {{since}} do {{until}}",
  bundleEdited:
    "Edycja pakietu na ''{{bundleName}}'' od {{since}} do {{until}}",
  bundleTimeRangeEdited:
    "Edycja czasu trwania pakietu na od {{since}} do {{until}}",
  bundleRemoved: "Usunięto pakiet",
  referralCreated: "Wystawiono skierowanie",
  referralRemoved: "Usunięto skierowanie",
  medicalOpinionAttached: "Załączono orzeczenie ważne do {{until}}",
  medicalOpinionEdited: "Edycja daty orzeczenia na {{until}}",
  employer: "Pracodawca",
  firstName: "Imię",
  lastName: "Nazwisko",
  idNumber: "Nr identyfikacyjny",
  occupationalMedicineStatus: "Status medycyny pracy",
  resetFilters: "Zresetuj filtry",
  noReferral: "Brak skierowania",
  issuedReferral: "Wydano skierowanie",
  medicalReportIssued: "Wydano orzeczenie",
  medicalOpinionsGoingToExpire: "Orzeczenia, z kończącym się terminem ważności",
}
