import * as yup from "yup"

import { AddReferralTemplateFormState } from "./AddReferralTemplateModal.component"
import i18n from "../../../../i18n/i18n"

const MAX_FIELD_CHARACTERS = 100

export const addReferralTemplateValidationSchema: yup.SchemaOf<AddReferralTemplateFormState> = yup.object().shape({
  createReferralTemplate: yup
    .string()
    .trim()
    .required(i18n.t("validations:fieldIsRequired"))
    .max(MAX_FIELD_CHARACTERS, i18n.t("validations:enterMaxChars_many", { count: MAX_FIELD_CHARACTERS })),
})