import {gql} from "@apollo/client"

export const DELETE_REFERRAL_TEMPLATE = gql`
  mutation deleteReferralTemplate(
    $id: uuid!
  ) {
    deleteReferralTemplatesByPk: delete_referral_templates_by_pk(
      id: $id
    ) {
      id
    }
  }
`