import { FC } from "react"

import {useRedirectToLogin} from "./Login.utils"

const LoginPage: FC = () => {
  useRedirectToLogin()
  return null
}

export default LoginPage
