import type { FC, ReactNode } from "react"
import {Role} from "../../../api/roles.types"
import {useAccessControl} from "../../../hooks/useAccessControl"

type Props = {
  allowedRoles: Role[];
  children: ReactNode;
}

const AccessControl: FC<Props> = ({ children, allowedRoles }) => {
  const isAllowed = useAccessControl(allowedRoles)

  return isAllowed
    ? (
      <>
        {children}
      </>
    )
    : null
}

export default AccessControl
