import { FC } from "react"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import clsx from "clsx"

import useUtilsStyles from "../../../../../../utils/utils.styles"
import { ReactComponent as IconMagnifyingGlass } from "../../../../../../assets/svg/magnifying-glass.svg"
import { useQueryDownload } from "../../../../../../api/reactQuery/queries/download"
import { Endpoint } from "../../../../../../api/endpoints.types"
import { useTranslation } from "react-i18next"
import Snackbar from "../../../../../common/snackbar/Snackbar"

interface IProps {
  id: string;
  name: string;
  isLoading?: boolean
}

export const DownloadMedicalReport: FC<IProps> = ({
  id,
  name,
  isLoading
}) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()

  const {
    isLoading: isReportLoading,
    isError: isError,
    refetch: download,
  } = useQueryDownload(
    id,
    name,
    "application/pdf",
    `${Endpoint.MEDICAL_REPORTS}/${id}/download`,
    "medicalReport",
    false
  )

  return (
    <Box
      display="flex"
      className={utilClasses.cursorPointer}
      alignItems="center"
      minHeight="48px"
      alignSelf="flex-end"
      onClick={() => download()}
    >
      <IconMagnifyingGlass />
      <Box ml="8px">
        <Typography
          variant="subtitle1"
          className={clsx(utilClasses.fontBold, utilClasses.textUnderline)}
        >
          {name}
        </Typography>
      </Box>
      {isReportLoading || isLoading && (
        <Box ml="8px">
          <CircularProgress size={20} />
        </Box>
      )}
      <Snackbar
        isVisible={isError}
        text={t("validations:cannotDownloadFile")}
        severity="error"
      />
    </Box>
  )
}