import { createStyles, makeStyles } from "@material-ui/core"

export const useFormFieldsStyles = makeStyles(() => createStyles({
  infoIconWrapper: {
    maxWidth: "16px",
    maxHeight: "16px",
    display: "inline-flex",
    marginLeft: "4px",
    "& svg": {
      width: "100%",
      height: "100%",
    }
  },
  list: {
    display: "list-item",
    "& li": {
      display: "list-item",
      listStyle: "initial",
      padding: "0 16px 0 0",
      margin: "8px 0 8px 16px"
    }
  },
  optionWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  }
}))