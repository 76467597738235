export const addReferral = {
  createOccupationalMedicineReferral: "Wystaw skierowanie medycyny pracy",
  exposures: "Narażenia",
  referral: "Skierowanie",
  employeeData: "Dane pracownika",
  pesel: "PESEL",
  dateOfBirth: "Data urodzenia",
  phoneNumber: "Nr telefonu",
  nameAndLastName: "Imię i nazwisko",
  email: "E-mail",
  referralType: "Rodzaj skierowania",
  preliminaryExamination: "Badanie wstępne",
  periodicExamination: "Badanie okresowe",
  routineExamination: "Badanie kontrolne",
  epidemicExamination: "Badania do celów sanitarno-epidemiologicznych",
  rangeActivitiesPossibilityTransmittingInfection: "Zakres czynności, przy których istnieje możliwość przeniesienia zakażenia",
  rangeActivitiesPossibilityTransmittingInfectionLabel: "Wpisz zakres czynności, przy których istnieje możliwość przeniesienia zakażenia",
  jobTitleDescription: "Określenie stanowiska pracy",
  jobTitleDescriptionLabel: "Rodzaj pracy, podstawowe czynności, sposób i czas ich wykonywania",
  chooseExposuresFactors: "Wybierz czynniki narażenia",
  choosenExposuresFactors: "Wybrane czynniki narażenia",
  physicalFactors: "Czynniki fizyczne",
  dusts: "Pyły",
  chemicalFactors: "Czynniki chemiczne",
  biologicalFactors: "Czynniki biologiczne",
  otherFactorsIncludingDangerous: "Inne czynniki, w tym niebezpieczne",
  enterExposure: "Wpisz czynniki (oddzielając je przecinkiem)",
  enterExposureNameOrChooseFromList: "Wpisz nazwę czynnika lub wybierz z listy",
  goToReferralDetails: "Przejdź do szczegółów skierowania",
  goBackToExposuresSelection: "Wróć do wyboru narażeń",
  createReferral: "Wystaw skierowanie",
  referralCreated: "Utworzono skierowanie",
  referralDeadlineDate: "Termin dostarczenia orzeczenia",
  typeOfJob: "Rodzaj wykonywanej pracy",
  extraRemarksLabel: "Dodatkowe uwagi",
  extraRemarksPlaceholder: "Wpisz dodatkowe uwagi dla lekarza",
  jobTypes: {
    type1: {
      label: "Prace na stanowiskach związanych z ciągłym kontaktem z ludźmi stwarzające zagrożenie przeniesienia drogą powietrzno-kropelkową i powietrzno-pyłową zakażenia na inne osoby, niezależnie od rodzaju wykonywanych czynności zawodowych",
      tooltipItems: {
        item1: "przedszkola, szkoły, uczelnie wyższe, placówki opiekuńczo-wychowawcze, rodzinne ośrodki diagnostyczno-konsultacyjne, ośrodki adopcyjno-opiekuńcze",
        item2: "zakłady opieki zdrowotnej",
        item3: "indywidulane/indywidualne specjalistyczne/ grupowe praktyki lekarskie/pielęgniarskie/położnicze",
        item4: "zakłady fryzjerskie, gabinety kosmetyczne, zakłady zajmujące się pielęgnacją ciała",
        item5: "instytucje i urzędy z bezpośrednią obsługą interesantów",
        item6: "transport publiczny, instytucje kultury",
        item7: "podmioty świadczące usługi transportowe i hotelarskie",
        item8: "zakłady karne, areszty śledcze, zakłady poprawcze, schroniska dla nieletnich, zakłady opiekuńczo wychowawcze, ośrodki dla uchodźców, policyjne pomieszczenia za osób zatrzymanych, policyjne izby dziecka, konwoje osób pozbawionych wolności, doprowadzenia osób zatrzymanych",
      }
    },
    type2: {
      label: "Prace na stanowiskach związanych z wytwarzaniem, pakowaniem, dystrybucją lub przechowywaniem nieopakowanej żywności, wody przeznaczonej do spożycia przez ludzi oraz leków doustnych, przygotowaniem i wydawaniem posiłków, wody przeznaczonej do spożycia przez ludzi i leków, a także myciem naczyń i pojemników przeznaczonych na żywność, wodę przeznaczoną do spożycia przez ludzi lub na leki, stwarzające zagrożenie przeniesienia drogą pokarmową zakażenia na inne osoby, niezależnie od rodzaju wykonywanych czynności zawodowych",
      tooltipItems: {
        item1: "zakłady żywienia zbiorowego",
        item2: "zakłady hurtowego i detalicznego obrotu nie opakowaną żywnością",
        item3: "zakłady uzdatniające i dostarczające wodę przeznaczoną do spożycia przez ludzi lub lód do celów spożywczych",
        item4: "zakłady produkujące lub wprowadzające żywność do obrotu, zakłady mleczarskie- pozyskaniu i przetwórstwie mleka oraz fermy w tym rolnicy gospodarujący indywidualnie",
        item5: "gospodarstwa rolne, w których produkuje się żywność w celu wprowadzenia do obrotu",
        item6: "apteki, punkty apteczne, sklepy zielarskie, hurtownie farmaceutyczne, zakłady produkujące lub wprowadzające do obrotu leki i środki farmaceutyczne doustne",
        item7: "zakłady opieki zdrowotnej, żłobki, zakłady pielęgnacyjno-opiekuńcze, zakłady opiekuńczo-lecznicze",
        item8: "przedszkola i miejsca przebywania dzieci do lat 6",
        item9: "podmioty świadczące usługi w wagonach restauracyjnych, barowych oraz przy przewozie nieopakowanych artykułów żywnościowych w wagonach chłodniach",
        item10: "podmioty świadczące usługi na statkach powietrznych i wodnych na stanowiskach stewardów i stewardes",
      }
    },
    type3: {
      label: "Prace na stanowiskach związanych z kontaktem z wodą oraz środkami służącymi pielęgnacji skóry lub śluzówek innych osób oraz stwarzających zagrożenie przeniesienia drogą kontaktową zakażenia na inne osoby, niezależnie od rodzaju wykonywanych czynności zawodowych ",
      tooltipItems: {
        item1: "przedszkola i inne miejsca przebywania dzieci do lat 6",
        item2: "zakłady opieki zdrowotnej",
        item3: "indywidualne/indywidualne specjalistyczne/ grupowe praktyki lekarskie/pielęgniarskie/położnicze",
        item4: "zakłady fryzjerskie, gabinety kosmetyczne, zakłady zajmujące się pielęgnacją ciała, zakłady, w których podejmowane są czynności, w których dochodzi do naruszenia ciągłości tkanek ludzkich",
        item5: "wytwórnie produktów leczniczych, wyrobów medycznych, kosmetyków oraz środków służących do mycia i pielęgnacji ciała",
        item6: "kąpieliska, łaźnie, sauny, baseny kąpielowe",
      }
    }
  },
  referralTemplates: {
    creatingTemplate: "Tworzenie szablonu",
    updateTemplate: "Aktualizuj szablon",
    typeTemplateName: "Wpisz nazwę szablonu",
    typeName: "Wpisz nazwę",
    referralTemplateCreated: "Utworzono szablon skierowania",
    createTemplateError: "Nie udało się stworzyć szablonu.",
    createTemplate: "Utwórz szablon",
    deleteTemplateSuccess: "Usunięto szablon",
    deleteTemplateError: "Nie udało się usunąć szablonu.",
    noIDontWant: "Nie, nie chcę",
    yesIWant: "Tak, chcę",
    deleteConfirm: "Czy na pewno chcesz usunąć szablon",
    templatesListPlaceholder: "Wybierz szablon z listy",
    templatesListLabel: "Wybierz szablon skierowania z listy",
    templateUpdated: "Zaaktualizowano szablon"

  }
}