import * as yup from "yup"

// NUMBERS

export const numberSchema = yup
  .number()
  .max(20000000)
  .nullable()
  .transform((value, originalValue) =>
    `${originalValue}`.trim() === "" ? null : value
  )
  .typeError("validations:valueMustBeNumber")

export const requiredNumberSchema = yup
  .number()
  .required()
  .max(20000000)
  .typeError("validations:valueMustBeNumber")

export const positiveIntegerNumberSchema = yup
  .number()
  .integer()
  .positive()
  .max(20000000)
  .nullable()
  .transform((value, originalValue) =>
    `${originalValue}`.trim() === "" ? null : value
  )
  .typeError("validations:valueMustBeNumber")

export const notNegativeNumberTwoDecimalPlaces = yup
  .number()
  .min(0, "validations:enterNotNegativeNumber")
  .max(20000000)
  .test(
    "max 2 decimal places",
    "validations:enterPositiveNumberWithTwoDecimalPlaces",
    (value) =>
      value === null || /^[0-9]*(\.[0-9]{0,2})?$/.test(`${value as number}`)
  )
  .nullable()
  .transform((value, originalValue) =>
    `${originalValue}`.trim() === "" ? null : value
  )
  .typeError("validations:valueMustBeNumber")

export const requiredNotNegativeNumberTwoDecimalPlaces = yup
  .number()
  .min(0, "validations:enterNotNegativeNumber")
  .max(20000000)
  .test(
    "max 2 decimal places",
    "validations:enterPositiveNumberWithTwoDecimalPlaces",
    (value) => /^[0-9]*(\.[0-9]{0,2})?$/.test(`${value as number}`)
  )
  .typeError("validations:valueMustBeNumber")

// STRINGS

export const stringSchema = yup.string().max(200).trim()

export const requiredStringSchema = yup.string().required().max(200).trim()

export const emailSchema = yup.string().email().max(200).trim()

export const requiredEmailSchema = yup
  .string()
  .required()
  .email()
  .max(200)
  .trim()

export const urlSchema = yup
  .string()
  .max(200)
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    {
      excludeEmptyString: true,
      message: "validations:enterValidUrl",
    }
  )
  .trim()

export const requiredUrlSchema = yup
  .string()
  .required()
  .max(200)
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "validations:enterValidUrl"
  )
  .trim()

export const maskedInputSchema = yup
  .string()
  .max(200)
  .test(
    "don't contain input mask placeholder _ (all places filled)",
    "validations:fillInMissingChars",
    (val) => !val?.includes("_")
  )
  .trim()

export const requiredMaskedInputSchema = yup
  .string()
  .required()
  .max(200)
  .test(
    "don't contain input mask placeholder _ (all places filled)",
    "validations:fillInMissingChars",
    (val) => !val?.includes("_")
  )
  .trim()

// DATES

export const requiredDateSchema = yup
  .date()
  .nullable()
  .required()
  .typeError("validations:enterValidDate")

export const dateSchema = yup
  .date()
  .typeError("validations:enterValidDate")
  .nullable()

export const phoneSchema = yup
  .string()
  .nullable()
  .required()
  .matches(/^\+\d{2,3}\d{8,10}$/, "validations:enterPhone")
  .trim()
