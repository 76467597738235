import {gql} from "@apollo/client"

export const GET_REFERRAL_TEMPLATES = gql`
  query getReferralTemplates(
    $employerId: String!
  ) {
    referralTemplates: referral_templates (where: {employer_id: {_eq: $employerId }}) {
      id
      name
    }
  }
`