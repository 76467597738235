import { FC } from "react"
import { Link as LinkRRD, LinkProps } from "react-router-dom"
import useLinkStyles from "./Link.styles"

const Link: FC<LinkProps> = ({ children, ...props }) => {
  const classes = useLinkStyles()

  return (
    <LinkRRD className={classes.root} {...props}>
      {children}
    </LinkRRD>
  )
}

export default Link
