import { FC } from "react"
import clsx from "clsx"
import { Typography } from "@material-ui/core"

import useUtilsStyles from "../../../../utils/utils.styles"

const FieldLabel: FC = ({ children }) => {
  const utilClasses = useUtilsStyles()

  return (
    <Typography
      variant="body1"
      className={clsx(utilClasses.fontBold, utilClasses.textGrey300)}
    >
      {children}
    </Typography>
  )
}

export default FieldLabel
