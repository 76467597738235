import { FC, useMemo } from "react"

import { InputAdornment, TextField as TextFieldMUI } from "@material-ui/core"
import get from "lodash.get"
import {Controller, useFormContext, useWatch} from "react-hook-form"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"

import { IProps } from "./TextField.types"
import FieldLabel from "../fieldLabel/FieldLabel"
import FieldSkeleton from "../../skeleton/fieldSkeleton/FieldSkeleton"

const TextField: FC<IProps> = ({
  name,
  label,
  error = false,
  errorMessage,
  disabled = false,
  placeholder,
  variant = "outlined",
  mask,
  formatChars,
  startAdornment,
  endAdornment,
  inputMode = "text",
  multiline = false,
  maxLength,
  isLoading = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const textFieldValue = useWatch({
    name
  })
  const { t } = useTranslation()
  const fieldError = get(errors, name, false)

  const inputProps = useMemo(
    () => ({
      inputMode,
      startAdornment: startAdornment ? (
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      ) : undefined,
      endAdornment: endAdornment ? (
        <InputAdornment position="end">{endAdornment}</InputAdornment>
      ) : undefined,
    }),
    [inputMode, startAdornment, endAdornment]
  )

  if ( isLoading ){
    return <FieldSkeleton/>
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {!!label && <FieldLabel>{label}</FieldLabel>}
          {mask ? (
            <InputMask
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              mask={mask}
              // There is no types for format chars in this library
              // but the prop formatChars exists
              // @ts-ignore:next-line
              formatChars={formatChars}
              disabled={disabled}
            >
              {() => (
                <TextFieldMUI
                  {...field}
                  variant={variant}
                  fullWidth
                  error={error || !!fieldError}
                  helperText={
                    errorMessage ||
                    (fieldError.message &&
                    typeof fieldError.message === "string"
                      ? t(fieldError.message)
                      : t(fieldError.message?.key, fieldError.message?.values))
                  }
                  disabled={disabled}
                  placeholder={placeholder}
                  InputProps={inputProps}
                />
              )}
            </InputMask>
          ) : (
            <TextFieldMUI
              {...field}
              variant={variant}
              fullWidth
              error={error || !!fieldError}
              helperText={
                multiline
                  ? (textFieldValue?.length ?? 0) + "/" + maxLength
                  : errorMessage ||
                (fieldError.message && typeof fieldError.message === "string"
                  ? t(fieldError.message)
                  : t(fieldError.message?.key, fieldError.message?.values))
              }
              disabled={disabled}
              placeholder={placeholder}
              InputProps={inputProps}
              inputProps={{ maxLength, id: name }}
              multiline={multiline}
            />
          )}
        </>
      )}
    />
  )
}

export default TextField
