import { FC } from "react"
import { Box } from "@material-ui/core"

import FieldLabel from "../../../common/field/fieldLabel/FieldLabel"
import useFrameStyles from "./Frame.styles"

interface IProps {
  title: string
}

const Frame: FC<IProps> = ({ title, children }) => {
  const classes = useFrameStyles()

  return (
    <Box>
      <FieldLabel>{title}</FieldLabel>
      <Box px="24px" py="16px" className={classes.frame}>
        {children}
      </Box>
    </Box>
  )
}

export default Frame
