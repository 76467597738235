export enum Endpoint {
  PATIENTS = "/patients",
  BUNDLES = "/bundles",
  EXPOSURES = "/exposures",
  MEDICAL_REPORTS = "/medical_reports",
  REFERRALS = "/referrals",
  EMPLOYERS = "/employers",
  REFERRALS_TEMPLATES = "/referrals/template"
}

export enum EndpointSSO {
  USERS = "/users",
}
