import { FC } from "react"
import { DialogProps, Box, Typography, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import Dialog from "../dialog/Dialog"
import { ReactComponent as IconInfo } from "../../../../assets/svg/info-indicator.svg"
import { ReactComponent as IconWarning } from "../../../../assets/svg/warning.svg"
import ButtonClose from "../../button/buttonClose/ButtonClose"
import useUtilsStyles from "../../../../utils/utils.styles"
import ButtonLoader from "../../button/buttonLoader/ButtonLoader"

interface IProps extends DialogProps {
  open: boolean
  onClose: () => void
  onActionButtonClick: () => void
  title?: string
  description?: string
  variant?: "info" | "warning"
  actionButtonText?: string
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined
  actionButtonLoading?: boolean
  actionButtonWidth?: string
}

const DialogConfirmation: FC<IProps> = ({
  open,
  onClose,
  title,
  description,
  variant = "info",
  actionButtonText = "Ok",
  maxWidth = "sm",
  onActionButtonClick,
  actionButtonLoading = false,
  actionButtonWidth = "100px",
  ...props
}) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} {...props}>
      <Box p="48px">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h2" className={utilClasses.fontMedium}>
            {title}
          </Typography>
          <ButtonClose onClick={onClose} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          my="32px"
          className={
            variant === "info"
              ? utilClasses.textPrimaryMain
              : utilClasses.textErrorMain
          }
        >
          {variant === "info" ? (
            <IconInfo width="60px" height="60px" />
          ) : (
            <IconWarning width="60px" height="60px" />
          )}
        </Box>
        {description && (
          <Box mb="32px">
            <Typography align="center" variant="subtitle1">
              {description}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          className={utilClasses.gap32}
        >
          <Button variant="text" color="primary" onClick={onClose}>
            {t("defaultTranslations:cancel")}
          </Button>
          <ButtonLoader
            loading={actionButtonLoading}
            onClick={onActionButtonClick}
            width={actionButtonWidth}
          >
            {actionButtonText}
          </ButtonLoader>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DialogConfirmation
