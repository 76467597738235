import { useMutation } from "@apollo/client"

import { DeleteReferralTemplateResponseData, DeleteReferralTemplateVariables } from "./deleteReferralTemplate.types"
import { MutationHookOptions } from "@apollo/client/react/types/types"
import { DELETE_REFERRAL_TEMPLATE } from "./deleteReferralTemplate.mutation"

export const useDeleteReferralTemplateMutation = (options?: MutationHookOptions<DeleteReferralTemplateResponseData, DeleteReferralTemplateVariables>) => {
  return useMutation<DeleteReferralTemplateResponseData, DeleteReferralTemplateVariables>(
    DELETE_REFERRAL_TEMPLATE,
    {
      ...options
    }
  )
}