import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import { BASE_URL_SSO } from "../../api/api.config"
import { EndpointSSO } from "../../api/endpoints.types"
import { IUserData } from "../../ts/interfaces/User"
import {RootState} from "../store.types"
import {redirectToLogout} from "../../pages/Login/Login.utils"
import {selectLoginRedirectUrl} from "../clinic/clinic.selectors"

const defaultOptions = {
  baseURL: BASE_URL_SSO,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
}

export const axiosSSOInstance = axios.create(defaultOptions)

export const getUser = createAsyncThunk<
  IUserData,
  { shouldIgnoreRedirect?: boolean } | undefined,
  { rejectValue: unknown }
>("user/getUser", async (args = { shouldIgnoreRedirect: false }, { rejectWithValue, getState }) => {
  const state = getState() as RootState
  try {
    const response = await axiosSSOInstance.get<IUserData>(EndpointSSO.USERS)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404 && !args.shouldIgnoreRedirect) {
      window.location.href = selectLoginRedirectUrl(state)
    }
    return rejectWithValue(JSON.stringify(error))
  }
})

export const logoutUser = createAsyncThunk(
  "user/logout",
  async (parameter, { getState }) => {
    const state = getState() as RootState
    redirectToLogout(state.clinic.clinicId)
  }
)

export const redirectToLogin = createAsyncThunk(
  "user/redirectToLogin",
  async (parameter, { getState }) => {
    const state = getState() as RootState
    window.location.href = selectLoginRedirectUrl(state)
  }
)
