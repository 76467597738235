import { FC } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import Frame from "../frame/Frame"
import FrameCell from "../frame/frameCell/FrameCell"

interface IProps {
  pesel: string | null
  birthday: string | null
  nameAndLastName: string
  phoneNumber: string
  email: string
}

const EmployeePersonalData: FC<IProps> = ({
  pesel,
  birthday,
  nameAndLastName,
  phoneNumber,
  email,
}) => {
  const { t } = useTranslation()

  return (
    <Frame title={t("addReferral:employeeData")}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FrameCell
            label={
              pesel ? t("addReferral:pesel") : t("addReferral:dateOfBirth")
            }
            value={pesel ? pesel : birthday || "-"}
          />
        </Grid>
        <Grid item xs={6}>
          <FrameCell label={t("addReferral:phoneNumber")} value={phoneNumber} />
        </Grid>
        <Grid item xs={6}>
          <FrameCell
            label={t("addReferral:nameAndLastName")}
            value={nameAndLastName}
          />
        </Grid>
        <Grid item xs={6}>
          <FrameCell label={t("addReferral:email")} value={email} />
        </Grid>
      </Grid>
    </Frame>
  )
}

export default EmployeePersonalData
