import { makeStyles, createStyles, Theme } from "@material-ui/core"

const useLinkStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  })
)

export default useLinkStyles
