import {FC, } from "react"
import {useAppSelector} from "../../../hooks/storeHooks/storeHooks"
import {selectUserChecked, selectUserData} from "../../../store/user/user.selectors"
import {useGetUser} from "./hooks/useGetUser"

const UserProvider: FC = ({ children }) => {
  useGetUser()
  const userChecked = useAppSelector(selectUserChecked)
  const userData = useAppSelector(selectUserData)

  if (!userChecked && !userData?.token?.accessToken) {
    return null
  }

  return <>{children}</>
}

export default UserProvider
