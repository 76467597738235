import { useState } from "react"

export const useDialog = () => {
  const [isOpened, setIsOpened] = useState(false)

  const openDialog = () => {
    setIsOpened(true)
  }

  const closeDialog = () => {
    setIsOpened(false)
  }

  return {
    isOpened,
    openDialog,
    closeDialog,
  }
}
