import { FC } from "react"
import { Box } from "@material-ui/core"

const PageContentLayout: FC = ({ children }) => {
  return (
    <Box p="24px" width="100%">
      {children}
    </Box>
  )
}

export default PageContentLayout
