import {useEffect, useRef, useState} from "react"
import {useAppDispatch, useAppSelector} from "../../../../hooks/storeHooks/storeHooks"
import {setClinicId} from "../../../../store/clinic/clinic.slice"
import {axiosSSOInstance} from "../../../../store/user/user.thunks"
import {selectClinicId} from "../../../../store/clinic/clinic.selectors"

export const useSetTenantToken = () => {
  const [hasSetTenantToken, setHasSetTenantToken] = useState(false)
  const xTenantToken = useAppSelector(selectClinicId)
  const interceptorId = useRef<number>()
  const dispatch = useAppDispatch()
  const clinicId = new URLSearchParams(window.location.search).get("clinicId")

  useEffect(() => {
    if (!clinicId) {
      return
    }

    dispatch(setClinicId(clinicId))
  }, [clinicId])

  useEffect(() => {
    if (interceptorId.current !== undefined) {
      axiosSSOInstance.interceptors.request.eject(interceptorId.current)
    }

    interceptorId.current = axiosSSOInstance.interceptors.request.use((config) => {
      if (config.headers) {
        config.headers["x-tenant"] = xTenantToken
      }

      return config
    })

    setHasSetTenantToken(true)

    return () => {
      if (interceptorId.current !== undefined) {
        axiosSSOInstance.interceptors.request.eject(interceptorId.current)
      }
    }
  }, [xTenantToken])

  return hasSetTenantToken
}
