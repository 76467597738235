import {useQuery} from "@apollo/client"

import {QueryHookOptions} from "@apollo/client/react/types/types"
import {HR_USER_EMPLOYERS_QUERY} from "./hrUserEmployers.query"
import {HrUserEmployersQueryResponse, HrUserEmployersQueryVariables} from "./hrUserEmployers.types"

const useHrUserEmployersQuery = (options?: QueryHookOptions<HrUserEmployersQueryResponse, HrUserEmployersQueryVariables>) =>
  useQuery<HrUserEmployersQueryResponse, HrUserEmployersQueryVariables>(HR_USER_EMPLOYERS_QUERY, options)

export {
  useHrUserEmployersQuery
}
