import { Box, Typography } from "@material-ui/core"
import { FC } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"

import EmployeeFields from "../../common/form/employeeFields/EmployeeFields"
import { IEditEmployeeFormState, IProps } from "./EditEmployee.types"
import {
  getFormStateFromApiData,
  validationSchema,
} from "./EditEmployee.config"
import Divider from "../../common/divider/Divider"
// import BundleFields from "./bundleFields/BundleFields"
import { useMutationPutPatients } from "../../../api/reactQuery/mutations/patients"
import ButtonLoader from "../../common/button/buttonLoader/ButtonLoader"
import Snackbar from "../../common/snackbar/Snackbar"
import { bundleDefaultOption } from "./bundleFields/BundleFields.config"
import CheckboxField from "../../common/field/checkboxField/CheckboxField"

const EditEmployee: FC<IProps> = ({ patientData, patientId }) => {
  const { t } = useTranslation()

  const methods = useForm<IEditEmployeeFormState>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: getFormStateFromApiData(patientData),
  })

  const { mutate, data, isLoading, isError } = useMutationPutPatients()

  const formSubmit: SubmitHandler<IEditEmployeeFormState> = (data) => {
    mutate({
      patientId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      pesel: data.pesel,
      active: !data.isInactive,
      employerId: data.employerId,
      birthday: data.birthday,
      position: data.position,
      identificationNumber: data.identificationNumber || null,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      activeBundle:
        data.bundleId === bundleDefaultOption.id
          ? null
          : {
              bundleId: data.bundleId,
              validFrom: data.activeSince!.toISOString(),
              validTo: data.activeUntil!.toISOString(),
            },
    })
  }

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(formSubmit)}>
          <Box mb="16px" display="flex" justifyContent="space-between">
            <Typography variant="h2">{t("employeeForm:editEmployee")}</Typography>
            <Box mb="16px">
              <CheckboxField
                name="isInactive"
                label={t("employeeForm:inactiveEmployee")}
              />
            </Box>
          </Box>
          <EmployeeFields />
          <Divider my="48px" />
          {/* https://telemedico.atlassian.net/browse/LESS-3135 */}
          {/* Hide fields because they are not used */}
          {/* <BundleFields /> */}
          {/* <Divider my="48px" /> */}
          <Box display="flex" justifyContent="end" mt="32px">
            <ButtonLoader loading={isLoading} type="submit">
              {t("employeeForm:saveEmployeeData")}
            </ButtonLoader>
          </Box>
        </form>
      </FormProvider>
      <Snackbar
        isVisible={isError}
        text={t("validations:errorOccuredTryAgain")}
        severity="error"
      />
      <Snackbar
        isVisible={!!data}
        text={t("employeeForm:employeeUpdatedSuccessfully")}
      />
    </Box>
  )
}

export default EditEmployee
