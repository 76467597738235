import { Box, Button } from "@material-ui/core"
import { FC, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconArrowLeft } from "../../../../assets/svg/chevron-left.svg"
import ButtonLoader from "../../../common/button/buttonLoader/ButtonLoader"

interface IProps {
  currentStep: number
  setStep: (step: number) => void
  isReferralPostRequestLoading: boolean;
  openAddReferralTemplateDialog: () => void;
}

const NavigationAndSubmitButtons: FC<IProps> = ({
  currentStep,
  setStep,
  isReferralPostRequestLoading,
  openAddReferralTemplateDialog,
}) => {
  const [shouldCheckForm, setShouldCheckForm] = useState(false)
  const { t } = useTranslation()
  const {
    trigger,
    formState: { errors },
    watch,
  } = useFormContext()

  const selectedReferralTemplate = watch("referralTemplates")

  const handleOpenAddReferralTemplateDialog = async () => {
    setShouldCheckForm(false)
    const isValid = await trigger()
    if (isValid) {
      openAddReferralTemplateDialog()
    }
  }

  useEffect(() => {
    // when form is triggered after button next click,
    // if there are no errors - go to 2nd step,
    // otherwise keep the user on step 1 and show validation message
    // shouldCheckForm prevents from setting to step 2 before button next click
    if (Object.keys(errors).length === 0 && shouldCheckForm) {
      setStep(2)
    }
  }, [errors])

  return (
    <>
      {currentStep === 1 && (
        <Box
          display="flex"
          justifyContent="end"
          gridGap={12}
        >
          <Button
            onClick={handleOpenAddReferralTemplateDialog}
          >
            {selectedReferralTemplate ? t("addReferral:referralTemplates:updateTemplate") : t("addReferral:referralTemplates:createTemplate")}
          </Button>
          <Button
            onClick={() => {
              trigger()
              setShouldCheckForm(true)
            }}
          >
            {t("addReferral:goToReferralDetails")}
          </Button>
        </Box>
      )}
      {currentStep === 2 && (
        <Box display="flex" justifyContent="space-between">
          <Button variant="text" onClick={() => setStep(1)}>
            <Box mr="8px" height="24px">
              <IconArrowLeft height="24px" width="24px" />
            </Box>
            {t("addReferral:goBackToExposuresSelection")}
          </Button>
          <ButtonLoader type="submit" loading={isReferralPostRequestLoading}>
            {t("addReferral:createReferral")}
          </ButtonLoader>
        </Box>
      )}
    </>
  )
}

export default NavigationAndSubmitButtons
