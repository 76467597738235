export enum ReferralTypes {
  INITIAL = "initial",
  CYCLIC = "cyclic",
  CONTROL = "control",
  EPIDEMIC = "epidemic",
}

export enum JobTypes {
  TYPE1 = "1",
  TYPE2 = "2",
  TYPE3 = "3",
}