import * as yup from "yup"
import {
  dateSchema,
  phoneSchema,
  requiredMaskedInputSchema,
  requiredStringSchema,
  stringSchema
} from "../../../../utils/yup"
import i18next from "i18next"
import {validatePolish} from "validate-polish"

export const employeeFieldsDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  pesel: "",
  birthday: null,
  noPesel: false,
  position: "",
  identificationNumber: "",
  address: "",
  postalCode: "",
  city: "",
  employerId: "",
}

export const employeeFieldsSchema = yup.object().shape({
  firstName: requiredStringSchema,
  lastName: requiredStringSchema,
  phone: phoneSchema,
  employerId:
    yup.string(),
  pesel: yup
    .string()
    .when("noPesel", {
      is: false,
      then: (schema) => schema
        .required(i18next.t("validations:fieldIsRequired"))
        .test("isValidPesel", i18next.t("validations:invalidNationalIdentificationNumber"), (pesel?: string) => !!pesel && validatePolish.pesel(pesel)),
    }),
  birthday: dateSchema.when("noPesel", {
    is: true,
    then: (schema) => schema.required(),
  }),
  position: requiredStringSchema,
  identificationNumber: stringSchema,
  address: requiredStringSchema,
  postalCode: requiredMaskedInputSchema,
  city: requiredStringSchema,
})
