import { FC } from "react"

import Routes from "./routes/Routes"
import useScrollToTop from "./hooks/scroll/useScrollToTop"

const App: FC = () => {
  useScrollToTop()

  return <Routes />
}

export default App
