import * as yup from "yup"
import { IPatient } from "../../../ts/interfaces/Patient"
import { requiredStringSchema } from "../../../utils/yup"

import { employeeFieldsSchema } from "../../common/form/employeeFields/EmployeeFields.config"
import { bundleDefaultOption } from "./bundleFields/BundleFields.config"

export const validationSchema = employeeFieldsSchema.shape({
  bundleId: requiredStringSchema,
  activeSince: yup
    .date()
    .nullable()
    .when("bundleId", {
      is: (val: string) => val !== bundleDefaultOption.id,
      then: yup.date().typeError("validations:fieldIsRequired").required(),
    }),
  activeUntil: yup
    .date()
    .nullable()
    .when("bundleId", {
      is: (val: string) => val !== bundleDefaultOption.id,
      then: yup
        .date()
        .typeError("validations:fieldIsRequired")
        .min(
          yup.ref("activeSince"),
          "validations:dateUntilMustBeAfterDateSince"
        )
        .required(),
    }),
})

export const getFormStateFromApiData = (patientData: IPatient) => {
  return {
    firstName: patientData?.firstName,
    lastName: patientData?.lastName,
    email: patientData?.externalData.email,
    phone: patientData?.externalData.phone,
    pesel: `${patientData.externalData.pesel}`,
    isInactive: !patientData.active,
    employerId: patientData.employer?.split("/").pop(),
    birthday: patientData.externalData.birthday
      ? new Date(patientData.externalData.birthday)
      : null,
    noPesel: !patientData.externalData.pesel,
    position: patientData?.position,
    identificationNumber: patientData.identificationNumber || "",
    address: patientData?.externalData?.address?.street,
    postalCode: patientData?.externalData?.address?.postalCode,
    city: patientData?.externalData?.address?.city,
    activeSince: patientData?.activeBundleInfo
      ? new Date(patientData.activeBundleInfo.validFrom)
      : null,
    activeUntil: patientData?.activeBundleInfo
      ? new Date(patientData.activeBundleInfo.validTo)
      : null,
    bundleId: patientData?.activeBundleInfo
      ? patientData.activeBundleInfo.bundle.id
      : bundleDefaultOption.id,
  }
}
