import {useQueryEmployers} from "../../../api/reactQuery/queries/employers"
import {SelectOption} from "../../../ts/types/SelectOption"
import {useMemo} from "react"
import {useGetHrUserEmployer} from "../../../hooks/useGetHrUserEmployer"

export const useEmployerSelect = () => {
  const {
    data: employers = { data: [] },
    isLoading: isLoadingEmployer,
    isError: isErrorEmployer,
  } = useQueryEmployers()
  const {
    loading: isLoadingHrUserEmployers,
    error: isErrorHrUserEmployers,
    data = { hrUserEmployers: { edges: [] } },
  } = useGetHrUserEmployer()
  const employersMap = useMemo(
    () =>  new Map(employers.data.map(employer => [employer.externalId, employer])),
    [employers.data]
  )

  const options = data.hrUserEmployers.edges.reduce<SelectOption[]>(
    (current, hrUserEmployer) => {
      const id = hrUserEmployer.node.hrEmployer.id
      const employer = employersMap.get(id)

      if (employer) {
        current.push({
          value: employer.id,
          label: employer.name,
        })
      }

      return current
    },
    [],
  )

  return {
    options,
    isLoading: isLoadingEmployer || isLoadingHrUserEmployers,
    isErrorOccurred: Boolean(isErrorEmployer || isErrorHrUserEmployers),
  }
}
