import {FC} from "react"
import {useAxiosAuthInterceptors} from "./hooks/useAxiosAuthInterceptors"
import {useSetAuthorizationHeader} from "./hooks/useSetAuthorizationHeader"

const InterceptorProvider: FC = ({ children }) => {
  useAxiosAuthInterceptors()
  useSetAuthorizationHeader()

  return <>{children}</>
}

export default InterceptorProvider
