import { RootState } from "../store.types"
import {BASE_URL_SSO} from "../../api/api.config"

export const selectClinicId = (state: RootState) => state.clinic.clinicId
export const selectLoginRedirectUrl = (state: RootState) => {
  const loginUrl = new URL(BASE_URL_SSO ?? "")
  const redirectUrl = new URL(window.location.origin)
  redirectUrl.searchParams.set("clinicId", state.clinic.clinicId)
  loginUrl.pathname = "login-auth-code-grant"
  loginUrl.searchParams.set("login_redirect", redirectUrl.toString())
  loginUrl.searchParams.set("x-tenant", state.clinic.clinicId)

  return loginUrl.toString()
}
