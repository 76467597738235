import { useMutation, useQueryClient } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import { IMedicalReport } from "../../../ts/interfaces/MedicalReport"

interface IPutArgs {
  id: string
  validUntil: string
}

export const useMutationPutMedicalReports = () => {
  const queryClient = useQueryClient()

  return useMutation<Omit<IMedicalReport, "file">, AxiosError, IPutArgs>(
    async ({ id, validUntil }) => {
      const response = await axios.put(`${Endpoint.MEDICAL_REPORTS}/${id}`, {
        validUntil,
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationDeleteMedicalReport = () => {
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError, string>(
    async (id) => {
      await axios.delete(`${Endpoint.MEDICAL_REPORTS}/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}