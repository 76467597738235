import axios from "axios"

import { BASE_URL } from "./api.config"

const defaultOptions = {
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
}

const axiosInstance = axios.create(defaultOptions)

export default axiosInstance
