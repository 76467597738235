import { useMutation } from "@apollo/client"

import { AddReferralTemplateResponseData, AddReferralTemplateVariables } from "./addReferralTemplate.types"
import { MutationHookOptions } from "@apollo/client/react/types/types"
import { ADD_REFERRAL_TEMPLATE } from "./addReferralTemplate.mutation"

export const useAddReferralTemplateMutation = (options?: MutationHookOptions<AddReferralTemplateResponseData, AddReferralTemplateVariables>) => {
  return useMutation<AddReferralTemplateResponseData, AddReferralTemplateVariables>(
    ADD_REFERRAL_TEMPLATE,
    {
      ...options
    }
  )
}