import { makeStyles } from "@material-ui/core"
import { createStyles } from "@material-ui/core/styles"

const useStatusTextStyles = makeStyles(() =>
  createStyles({
    typographyWithIcon: {
      display: "flex",
      alignItems: "center",
      color: "inherit",
    },
    icon: {
      marginRight: "16px",
      color: "inherit",
      flexShrink: 0,
    },
  })
)

export default useStatusTextStyles
