import { useMutation, useQueryClient } from "react-query"
import { AxiosError } from "axios"

import axios from "../../api"
import { Endpoint } from "../../endpoints.types"
import { IPatient, IPatientPut } from "../../../ts/interfaces/Patient"
import { IEmployeeFields } from "../../../components/common/form/employeeFields/EmployeeFields.types"
import { IMedicalReport } from "../../../ts/interfaces/MedicalReport"
import { format } from "date-fns"

interface IPutPatientsArgs
  extends Omit<
    IEmployeeFields,
    "identificationNumber" | "noPesel" | "birthday"
  > {
  active: boolean
  activeBundle: {
    bundleId: string
    validFrom: string
    validTo: string
  } | null
  patientId: string
  identificationNumber: string | null
  birthday: Date | null
}

interface IPostMedicalReportArgs {
  patientId: string
  file?: File
  validUntil: string
}

export const useMutationPostPatients = () => {
  const queryClient = useQueryClient()

  return useMutation<IPatient, AxiosError, IEmployeeFields>(
    async ({
      firstName,
      lastName,
      email,
      phone,
      pesel,
      birthday,
      position,
      identificationNumber,
      address,
      postalCode,
      city,
      employerId,
    }) => {
      const res = await axios.post(Endpoint.PATIENTS, {
        firstName,
        lastName,
        position,
        identificationNumber,
        externalData: {
          phone,
          email,
          employerId: employerId || undefined,
          pesel: pesel ? pesel : null,
          birthday: birthday ? format(birthday, "yyyy-MM-dd") : null,
          address: {
            street: address,
            postalCode,
            city,
          },
        },
      })
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationPutPatients = () => {
  const queryClient = useQueryClient()

  return useMutation<IPatientPut, AxiosError, IPutPatientsArgs>(
    async ({
      patientId,
      firstName,
      lastName,
      email,
      phone,
      active,
      pesel,
      employerId,
      birthday,
      position,
      identificationNumber,
      address,
      postalCode,
      city,
      activeBundle,
    }) => {
      const res = await axios.put(`${Endpoint.PATIENTS}/${patientId}`, {
        firstName,
        lastName,
        position,
        identificationNumber,
        active,
        externalData: {
          phone,
          email,
          employerId: employerId || undefined,
          pesel: pesel ? pesel : null,
          birthday: birthday ? format(birthday, "yyyy-MM-dd") : null,
          address: {
            street: address,
            postalCode,
            city,
          },
        },
        activeBundle,
      })
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}

export const useMutationPostMedicalReport = () => {
  const queryClient = useQueryClient()

  return useMutation<IMedicalReport, AxiosError, IPostMedicalReportArgs>(
    async ({ patientId, file, validUntil }) => {
      const formData = new FormData()

      file && formData.append("file", file)
      formData.append("validUntil", validUntil)

      const response = await axios.post(
        `${Endpoint.PATIENTS}/${patientId}/medical_report`,
        formData
      )
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("patients")
      },
    }
  )
}
