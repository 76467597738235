import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { heights } from "../../themes/heights"

const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    contentWrapper: {
      minHeight: heights.fullScreen,
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  })
)

export default useLayoutStyles
