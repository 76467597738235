import { FC } from "react"
import { Box, BoxProps } from "@material-ui/core"
import theme from "../../../themes/default/theme"

interface IProps extends BoxProps {
  width?: string
  height?: string
  bgColor?: string
}

const Divider: FC<IProps> = ({
  width = "100%",
  height = "1px",
  bgColor = theme.palette.grey[200],
  ...props
}) => {
  return <Box width={width} height={height} bgcolor={bgColor} {...props}></Box>
}

export default Divider
