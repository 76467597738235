import { FC } from "react"
import { Box, Button, ButtonProps, CircularProgress } from "@material-ui/core"

import useUtilsStyles from "../../../../utils/utils.styles"

interface IProps extends ButtonProps {
  loading: boolean
  width?: string
}

const ButtonLoader: FC<IProps> = ({
  children,
  loading,
  width = "200px",
  ...props
}) => {
  const utilClasses = useUtilsStyles()

  return (
    <Button {...props} disabled={loading}>
      <Box width={width} height="24px" className={utilClasses.textWhite}>
        {loading ? <CircularProgress size="24px" color="inherit" /> : children}
      </Box>
    </Button>
  )
}

export default ButtonLoader
