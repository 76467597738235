import { RoutePath } from "../../routes/Routes.types"
import { ReactComponent as IconCase } from "../../assets/svg/briefcase.svg"

import i18n from "../../i18n/i18n"

export const navItems = [
  {
    id: 1,
    text: i18n.t("header:employees"),
    path: RoutePath.HOMEPAGE,
    icon: IconCase,
  },
]
