import { RootState } from "../store.types"

export const selectUserData = (state: RootState) =>
  state.user.data
export const selectIsAllClinicsAdmin = (state: RootState): boolean =>
  !!state.user.data?.isAllClinicsAdmin
export const selectAuthenticated = (state: RootState): boolean =>
  state.user.authenticated
export const selectUserChecked = (state: RootState): boolean =>
  state.user.userChecked
export const selectUserError = (state: RootState): boolean => state.user.error
export const selectIsInterceptorReady = (state: RootState): boolean =>
  state.user.isInterceptorReady
