import { FC } from "react"
import { Box, Typography } from "@material-ui/core"

import { tableHeaders } from "../PatientsTable.config"
import usePatientsTableStyles from "../PatientsTable.styles"
import { useAppSelector } from "../../../../../hooks/storeHooks/storeHooks"

const TableHeader: FC = () => {
  const classes = usePatientsTableStyles()

  const shouldShowEmployerFields = useAppSelector(state => state.user.shouldShowEmployerFields)

  return (
    <thead className={classes.tableHead}>
      <tr className={classes.tableRow}>
        {tableHeaders.map(
          (tableHeader) =>
            (!tableHeader.forAllClinicsAdmin ||
              (tableHeader.forAllClinicsAdmin && shouldShowEmployerFields)) && (
              <th key={tableHeader.id}>
                <Box minWidth={tableHeader.width}>
                  <Typography className={classes.headerText} variant="body2">
                    {tableHeader.text}
                  </Typography>
                </Box>
              </th>
            )
        )}
      </tr>
    </thead>
  )
}

export default TableHeader
