import { FC } from "react"
import {
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core"
import { format } from "date-fns"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import useUtilsStyles from "../../../../../../../utils/utils.styles"
import { getActionMessage } from "./LogsTable.config"
import { ILog } from "../../../../../../../ts/interfaces/Log"

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    verticalAlign: "top",
    wordBreak: "break-word",
  },
})(TableCellMUI)

interface IProps {
  logs: ILog[]
}

const LogsTable: FC<IProps> = ({ logs }) => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table
        style={{
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20%" }}>
              <Typography
                variant="body1"
                className={clsx(
                  utilClasses.fontBold,
                  utilClasses.textTextSecondary
                )}
              >
                {t("usersList:date")}
              </Typography>
            </TableCell>
            <TableCell style={{ width: "30%" }}>
              <Typography
                variant="body1"
                className={clsx(
                  utilClasses.fontBold,
                  utilClasses.textTextSecondary
                )}
              >
                {t("usersList:responsiblePerson")}
              </Typography>
            </TableCell>
            <TableCell style={{ width: "50%" }}>
              <Typography
                variant="body1"
                className={clsx(
                  utilClasses.fontBold,
                  utilClasses.textTextSecondary
                )}
              >
                {t("usersList:action")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...logs]
            .sort((a, b) => Date.parse(a.createdAt) + Date.parse(b.createdAt))
            .map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className={utilClasses.fontBold}
                  >
                    {format(new Date(log.createdAt), "yyyy-MM-dd HH:mm")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className={utilClasses.fontBold}
                  >
                    {log.authorDescription || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className={utilClasses.fontBold}
                  >
                    {`${getActionMessage(
                      log.action,
                      log.subject,
                      log.newValues
                    )}`}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LogsTable
