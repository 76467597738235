import { useQuery } from "@apollo/client"

import { ReferralTemplatesResponseData, ReferralTemplatesVariables } from "./getReferralTemplates.types"
import { QueryHookOptions } from "@apollo/client/react/types/types"

import { GET_REFERRAL_TEMPLATES } from "./getReferralTemplates.query"

export const useGetReferralTemplates = (options?: QueryHookOptions<ReferralTemplatesResponseData, ReferralTemplatesVariables>) => {
  return useQuery<ReferralTemplatesResponseData, ReferralTemplatesVariables>(
    GET_REFERRAL_TEMPLATES,
    options
  )
}