import { Box } from "@material-ui/core"
import { FC, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useLocation, useNavigate } from "react-router-dom"

import EmployeePersonalData from "./employeePersonalData/EmployeePersonalData"
import { IAddReferralFormState, LocationState } from "./AddReferral.types"
import FormHeader from "./formHeader/FormHeader"
import NavigationAndSubmitButtons from "./navigationAndSubmitButtons/NavigationAndSubmitButtons"
import FormFields from "./formFields/FormFields"
import { referralTypes } from "./formFields/FormFields.config"
import FilledData from "./filledData/FilledData"
import { RoutePath } from "../../../routes/Routes.types"
import { useQueryPatient } from "../../../api/reactQuery/queries/patients"
import { useMutationPostReferral, useMutationUpdateReferral } from "../../../api/reactQuery/mutations/referrals"
import { Skeleton } from "@material-ui/lab"
import StatusText from "../../common/statusText/StatusText"
import { useTranslation } from "react-i18next"
import useUtilsStyles from "../../../utils/utils.styles"
import Snackbar from "../../common/snackbar/Snackbar"
import { format } from "date-fns"
import { addReferralsValidationSchema } from "./AddReferral.validation"
import { ReferralTypes } from "./formFields/FormFields.enum"
import AddReferralTemplateModal from "./addReferralTemplateModal/AddReferralTemplateModal.component"
import { useGetReferralTemplates } from "../../../api/apolloClient/query/getReferralTemplates/getReferralTemplates.hook"
import { extractIdFromString } from "./AddReferral.utils"

const AddReferral: FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const [isReferralTemplateTrigger, setIsReferralTemplateTrigger] = useState(false)
  const [selectedReferralId, setSelectedReferralId] = useState("")

  const locationState = location.state as LocationState
  const employeeId = locationState ? locationState.employeeId : ""

  const methods = useForm<IAddReferralFormState>({
    defaultValues: {
      referralType: referralTypes[0].value,
      jobTitleDescription: "",
      rangeActivitiesPossibilityTransmittingInfection: "",
      physicalFactors: [],
      dusts: [],
      chemicalFactors: [],
      biologicalFactors: [],
      otherFactors: [],
      jobType: "",
      referralDeadlineDate: new Date(),
      extraRemarks: "",
      referralTemplates: null,
    },
    mode: "all",
    resolver: yupResolver(addReferralsValidationSchema),
  })

  const { data, isLoading, isError } = useQueryPatient(employeeId)
  const employerId = extractIdFromString(data?.employer)
  const { data: { referralTemplates } = {}, refetch } = useGetReferralTemplates({
    skip: !employerId,
    variables: {
      employerId: employerId ?? ""
    },
  })

  const {
    mutate: postReferral,
    isLoading: isLoadingPost,
    isSuccess: isSuccessPost,
    isError: isErrorPost,
  } = useMutationPostReferral()

  const {
    mutate: updateReferral,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate,
  } = useMutationUpdateReferral()

  const openDialog = () => {
    setIsDialogOpened(true)
  }

  const closeDialog = () => {
    setIsDialogOpened(false)
  }

  const setStep = (step: number) => {
    setCurrentStep(step)
  }

  const formSubmit = async (payload: IAddReferralFormState, templateId?: string, update?: boolean) => {
    const isEpidemicReferralType = payload.referralType === ReferralTypes.EPIDEMIC
    templateId ? setIsReferralTemplateTrigger(true) : setIsReferralTemplateTrigger(false)

    if (data) {
      const requestBody = {
        employerId: employerId ?? "",
        referralId: templateId ? selectedReferralId : undefined,
        patientId: templateId ? undefined : data.id,
        jobTitleDescription: isEpidemicReferralType ? undefined : payload.jobTitleDescription,
        exposureIds: isEpidemicReferralType
        ? []
        : [
          ...payload.physicalFactors.map((el) => `${el.value}`),
          ...payload.dusts.map((el) => `${el.value}`),
          ...payload.chemicalFactors.map((el) => `${el.value}`),
          ...payload.biologicalFactors.map((el) => `${el.value}`),
          ...payload.otherFactors.map((el) => `${el.value}`),
        ],
        type: payload.referralType,
        referralDeadlineDate: payload.referralDeadlineDate
          ? format(payload.referralDeadlineDate, "yyyy-MM-dd")
          : null,
        jobType: isEpidemicReferralType ? parseInt(payload.jobType) : undefined,
        rangeActivitiesPossibilityTransmittingInfection: isEpidemicReferralType ? payload.rangeActivitiesPossibilityTransmittingInfection : undefined,
        extraRemarks: payload.extraRemarks,
        templateId: templateId ?? undefined
      }
      update ? updateReferral(requestBody) : postReferral(requestBody)
    }
  }

  useEffect(() => {
    if (employeeId === "") {
      navigate(RoutePath.HOMEPAGE)
    }
  }, [])

  useEffect(() => {
    if (!isSuccessPost) {
      return
    }

    navigate(RoutePath.EMPLOYEE_BY_ID.replace(":id", employeeId))
  }, [isSuccessPost])

  return (
    <Box>
      <FormHeader currentStep={currentStep} />
      <Box mb="48px">
        {data && (
          <EmployeePersonalData
            nameAndLastName={`${data.firstName} ${data.lastName}`}
            pesel={data.externalData.pesel}
            birthday={data.externalData.birthday}
            phoneNumber={data.externalData.phone}
            email={data.externalData.email}
          />
        )}
        {isLoading && <Skeleton variant="rect" width="100%" height="122px" />}
        {isError && (
          <Box className={utilClasses.textErrorMain}>
            <StatusText text={t("validations:failedToFetch")} />
          </Box>
        )}
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((payload) => formSubmit(payload))}>
          <Box mb="24px">
            {currentStep === 1 && (
              <FormFields
                referralTemplates={referralTemplates}
                refetchTemplates={refetch}
                setSelectedReferralId={(id) => setSelectedReferralId(id)}
              />
            )}
            {currentStep === 2 && <FilledData />}
          </Box>
          <NavigationAndSubmitButtons
            currentStep={currentStep}
            setStep={setStep}
            isReferralPostRequestLoading={isLoadingPost || isLoadingUpdate}
            openAddReferralTemplateDialog={openDialog}
          />
        </form>
        <Snackbar
          isVisible={isSuccessPost}
          text={isReferralTemplateTrigger ? t("addReferral:referralTemplates:referralTemplateCreated") : t("addReferral:referralCreated")}
        />
        <Snackbar
          isVisible={isSuccessUpdate}
          text={t("addReferral:referralTemplates:templateUpdated")}
        />
        <Snackbar
          isVisible={isErrorPost}
          text={t("validations:errorOccuredTryAgain")}
          severity="error"
        />
        <Snackbar
          isVisible={isErrorUpdate}
          text={t("addReferral:referralTemplates:createTemplateError")}
          severity="error"
        />
        <AddReferralTemplateModal
          isOpen={isDialogOpened}
          handleClose={closeDialog}
          handleAddReferral={(templateId, update) => methods.handleSubmit((payload) => formSubmit(payload, templateId, update))()}
          refetchTemplates={refetch}
          employerId={employerId}
        />
      </FormProvider>
    </Box>
  )
}

export default AddReferral
