const MuiButton = {
  color: "primary" as
    | "inherit"
    | "primary"
    | "secondary"
    | "default"
    | undefined,
  variant: "contained" as "text" | "outlined" | "contained" | undefined,
}

const MuiButtonBase = {
  disableRipple: true,
}

export const buttonBase = {
  MuiButton,
  MuiButtonBase,
}
