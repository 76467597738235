import i18n from "../../../../i18n/i18n"
import { JobTypes, ReferralTypes } from "./FormFields.enum"

export const referralTypes = [
  {
    value: ReferralTypes.INITIAL,
    label: i18n.t("addReferral:preliminaryExamination"),
  },
  {
    value: ReferralTypes.CYCLIC,
    label: i18n.t("addReferral:periodicExamination"),
  },
  {
    value: ReferralTypes.CONTROL,
    label: i18n.t("addReferral:routineExamination"),
  },
  {
    value: ReferralTypes.EPIDEMIC,
    label: i18n.t("addReferral:epidemicExamination"),
  },
]

export const jobTypes = [
  {
    value: JobTypes.TYPE1,
    label: i18n.t("addReferral:jobTypes:type1:label"),
  },
  {
    value: JobTypes.TYPE2,
    label: i18n.t("addReferral:jobTypes:type2:label"),
  },
  {
    value: JobTypes.TYPE3,
    label: i18n.t("addReferral:jobTypes:type3:label"),
  },
]