import { makeStyles, createStyles, Theme } from "@material-ui/core"

const useStepIndicatorStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      borderRadius: 9999,
      height: "20px",
      width: "20px",
      fontSize: "13px",
      fontWeight: 700,
      lineHeight: "16px",
      background: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    },
    disabledBg: {
      background: theme.palette.grey[200],
    },
    disabledText: {
      color: theme.palette.grey[300],
    },
  })
)

export default useStepIndicatorStyles
