import { Box } from "@material-ui/core"
import clsx from "clsx"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import useUtilsStyles from "../../../../../utils/utils.styles"
import usePatientsTableStyles from "../PatientsTable.styles"
import {
  IPatient,
  IPatientNoExternalData,
} from "../../../../../ts/interfaces/Patient"
import { ReactComponent as IconDots } from "../../../../../assets/svg/three-dots.svg"
import {
  getNewestDate,
  isNewestMedicalReportGoingToExpire,
} from "./TableBody.utils"
import {useAppSelector} from "../../../../../hooks/storeHooks/storeHooks"
import {useQueryEmployers} from "../../../../../api/reactQuery/queries/employers"
import {StyledTypography} from "./TableBody.styles"

interface IProps {
  data: IPatientNoExternalData[]
  handleActionButtonClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    patient: IPatientNoExternalData
  ) => void
}

const TableBody: FC<IProps> = ({ data, handleActionButtonClick }) => {
  const utilClasses = useUtilsStyles()
  const classes = usePatientsTableStyles()
  const shouldShowEmployerFields = useAppSelector(state => state.user.shouldShowEmployerFields)

  const { data: employers = { data: [] } } = useQueryEmployers()

  const { t } = useTranslation()

  const getEmployerName = (patientEmployerId: IPatient["employer"]) => {
    if (!patientEmployerId || !employers.data.length) return "-"
    const employer = employers.data.find(
      (employer) => employer.id === patientEmployerId!.slice(15)
    )
    return employer?.name || "-"
  }

  return (
    <tbody>
      {data.map((patient) => (
        <tr key={patient.id} className={classes.tableRow}>
          <td>
            <StyledTypography
              $isActive={patient.active}
              variant="subtitle1"
              className={utilClasses.fontMedium}
            >
              {`${patient.firstName} ${patient.lastName}`}
            </StyledTypography>
          </td>
          <td>
            <StyledTypography
              $isActive={patient.active}
              variant="subtitle1"
              className={utilClasses.fontMedium}
            >
              {
                patient.active
                  ? t("usersList:activeEmployee")
                  : t("usersList:inactiveEmployee")
              }
            </StyledTypography>
          </td>
          {shouldShowEmployerFields
            && (
              <td>
                <StyledTypography
                  $isActive={patient.active}
                  variant="subtitle1"
                  className={utilClasses.fontMedium}
                >
                  {getEmployerName(patient.employer)}
                </StyledTypography>
              </td>
            )}
          <td>
            <StyledTypography
              $isActive={patient.active}
              variant="subtitle1"
              className={utilClasses.fontMedium}
            >
              {patient.identificationNumber || "-"}
            </StyledTypography>
          </td>
          {/* https://telemedico.atlassian.net/browse/LESS-3135 */}
          {/* Hide fields because they are not used */}
          {/*<td>*/}
          {/*  <Typography variant="subtitle1" className={utilClasses.fontMedium}>*/}
          {/*    {patient.activeBundleInfo?.bundle?.name || "-"}*/}
          {/*  </Typography>*/}
          {/*</td>*/}
          {/*<td>*/}
          {/*  <Typography variant="subtitle1" className={utilClasses.fontMedium}>*/}
          {/*    {patient.activeBundleInfo?.active ? t("active") : t("notActive")}*/}
          {/*  </Typography>*/}
          {/*</td>*/}
          {/*<td>*/}
          {/*  <Typography variant="subtitle1" className={utilClasses.fontMedium}>*/}
          {/*    {patient.activeBundleInfo?.validFrom*/}
          {/*      ? format(*/}
          {/*          new Date(patient.activeBundleInfo.validFrom),*/}
          {/*          "dd.MM.yyyy"*/}
          {/*        )*/}
          {/*      : "-"}*/}
          {/*  </Typography>*/}
          {/*</td>*/}
          {/*<td>*/}
          {/*  <Typography variant="subtitle1" className={utilClasses.fontMedium}>*/}
          {/*    {patient.activeBundleInfo?.validTo*/}
          {/*      ? format(*/}
          {/*          new Date(patient.activeBundleInfo.validTo),*/}
          {/*          "dd.MM.yyyy"*/}
          {/*        )*/}
          {/*      : "-"}*/}
          {/*  </Typography>*/}
          {/*</td>*/}
          <td>
            <StyledTypography
              $isActive={patient.active}
              variant="subtitle1"
              className={clsx(
                utilClasses.fontMedium,
                utilClasses.textUnderline
              )}
            >
              {!patient.referrals.length
                ? t("usersList:createReferral")
                : !patient.medicalReports.length
                  ? t("usersList:referralDetails")
                  : t("usersList:medicalOpinion")}
            </StyledTypography>
          </td>
          <td>
            <StyledTypography
              $isActive={patient.active}
              variant="subtitle1"
              className={clsx(
                utilClasses.fontNormal,
                isNewestMedicalReportGoingToExpire(patient.medicalReports) && [
                  utilClasses.textErrorMain,
                  utilClasses.fontBold,
                ]
              )}
            >
              {patient.medicalReports.length
                ? format(getNewestDate(patient.medicalReports), "dd.MM.yyyy")
                : "-"}
            </StyledTypography>
          </td>
          <td>
            <Box display="flex" justifyContent="center" alignItems="center">
              <button
                className={classes.actionButton}
                onClick={(event) => handleActionButtonClick(event, patient)}
              >
                <IconDots height="24px" width="24px" />
              </button>
            </Box>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableBody
