import { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {redirectToLogout} from "../../pages/Login/Login.utils"
import {useAppSelector} from "../../hooks/storeHooks/storeHooks"
import {selectClinicId} from "../../store/clinic/clinic.selectors"
import { navItems } from "./Header.config"
import useHeaderStyles from "./Header.styles"
import MenuItem from "./menuItem/MenuItem"
import useUtilsStyles from "../../utils/utils.styles"
import {useHrUserQuery} from "../../api/apolloClient/query/hrUser/hrUser.hook"
import {selectUserData} from "../../store/user/user.selectors"
import { Role } from "../../api/roles.types"

const Header: FC = () => {
  const {t} = useTranslation()
  const classes = useHeaderStyles()
  const utilClasses = useUtilsStyles()
  const xTenant = useAppSelector(selectClinicId)
  const user = useAppSelector(selectUserData)
  const isHrAdminRole = user?.token?.["v4-roles"].includes(Role.ROLE_HR_ADMIN)
  const {data} = useHrUserQuery({
    variables: {
      id: user?.token?.sub ?? ""
    },
    skip: !user
  })

  return (
    <header className={classes.root}>
      <nav>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box component="ul" display="inline-flex">
            {navItems.map((item) => (
              <NavLink key={item.id} to={item.path} end className={classes.link}>
                <MenuItem>
                  <Box display="flex">
                    <Box
                      flexShrink={0}
                      display="flex"
                      justifyContent="center"
                      mr="8px"
                  >
                    <item.icon height="24px" width="24px" />
                  </Box>
                    <Typography
                      variant="subtitle1"
                      className={utilClasses.fontBold}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </MenuItem>
              </NavLink>
            ))}
          </Box>
          {isHrAdminRole
            ? null
            : (
              <Typography
                className={classes.employerName}
              >
                {data?.hrUserEmployers?.edges?.[0]?.node?.hrEmployer?.name}
              </Typography>
            )
          }
          <Box
            className={classes.logoutBtn}
            mt={1}
            onClick={() => redirectToLogout(xTenant)}
          >
            <Typography
              variant="subtitle1"
            >
              {t("header:logout")}
            </Typography>
          </Box>
        </Box>
      </nav>
    </header>
)
}

export default Header
