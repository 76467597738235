import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"
import { FC, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconUpload } from "../../../../assets/svg/upload.svg"
import useUtilsStyles from "../../../../utils/utils.styles"
import DateField from "../../../common/field/dateField/DateField"

const UploadFormFields: FC = () => {
  const { register, watch } = useFormContext()
  const utilClasses = useUtilsStyles()
  const hiddenFileInputEl = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  const { ref, ...fields } = register("file")

  const handleAttachmentClick = () => {
    if (hiddenFileInputEl.current) {
      hiddenFileInputEl.current.click()
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={utilClasses.gap32}
    >
      <input
        {...fields}
        ref={(instance) => {
          ref(instance)
          hiddenFileInputEl.current = instance
        }}
        type="file"
        className={utilClasses.displayNone}
        accept="application/pdf"
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          className={clsx(
            utilClasses.textPrimaryMain,
            utilClasses.cursorPointer
          )}
          onClick={handleAttachmentClick}
        >
          <IconUpload height="96px" width="96px" />
        </Box>
        <Typography variant="body2" align="center">
          (
          {!!watch("file")
            ? watch("file")[0]
              ? watch("file")[0].name
              : t("employeeForm:fileNotChosen")
            : t("employeeForm:fileNotChosen")}
          )
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography align="center">
          {t("employeeForm:occupationalMedicineValidUntil")}:
        </Typography>
        <Box width="170px">
          <DateField name="validUntil" disablePast />
        </Box>
      </Box>
    </Box>
  )
}

export default UploadFormFields
