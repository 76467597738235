import { FC } from "react"
import { FormHelperText, RadioGroup } from "@material-ui/core"
import { Controller, useFormContext } from "react-hook-form"

import { IProps } from "./RadioFieldGroup.types"

const RadioFieldGroup: FC<IProps> = ({ children, name, label, row, id }) => {
  const { control, formState: { errors } } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <RadioGroup aria-label={label} row={row} id={id} {...field}>
          {children}
          <FormHelperText error>
            {errors?.[name]?.message}
          </FormHelperText>
        </RadioGroup>
      )}
      name={name}
      control={control}
    />
  )
}

export default RadioFieldGroup
