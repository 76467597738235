import React, { FC } from "react"

import { TextField } from "@material-ui/core"
import { Autocomplete as AutocompleteMUI, createFilterOptions } from "@material-ui/lab"
import get from "lodash.get"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ReactComponent as IconChevronDown } from "../../../../assets/svg/chevron-down.svg"
import FieldLabel from "../fieldLabel/FieldLabel"
import { IProps } from "./Autocomplete.types"
import {AutocompleteRenderInputParams} from "@material-ui/lab/Autocomplete/Autocomplete"

const filter = createFilterOptions()

const Autocomplete: FC<IProps> = ({
  name,
  options,
  label,
  error,
  errorMessage,
  disabled,
  placeholder,
  multiple = false,
  onInputChange,
  loading,
  canCreateNewOption = false,
  renderOption,
  onChangeSelected,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { t } = useTranslation()

  const fieldError = get(errors, name, false)

  // eslint-disable-next-line
  const onChange = (data: any, field: any) => {
    if(multiple) {
      data.map((obj: { label: string; value: string }) => {
        if (obj.label.indexOf( t("add") ) !== -1) {
          obj.label = obj.value
        }
  
        return obj
      })
    }

    if (onChangeSelected) {
      onChangeSelected(data)
    }

    field.onChange(data)
  }

  // eslint-disable-next-line
  const filterOptions = (options: any, params: any) => {
    const filtered = filter(options, params)

    if (canCreateNewOption && params.inputValue !== "") {
      filtered.push({
        value: params.inputValue,
        label: `${t("add")} "${params.inputValue}"`
      })
    }

    return filtered
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      error={error || !!fieldError}
      helperText={
        errorMessage ||
        (fieldError.message && typeof fieldError.message === "string"
          ? t(fieldError.message)
          : t(fieldError.message?.key, fieldError.message?.values))
      }
      disabled={disabled}
      variant="standard"
      placeholder={placeholder}
    />
  )

  return (
    <Controller
      render={({ field }) => (
        <>
          {!!label && <FieldLabel>{label}</FieldLabel>}
          <AutocompleteMUI
            {...field}
            noOptionsText={t("noResults")}
            loadingText={t("loadingDots")}
            loading={loading}
            onInputChange={onInputChange}
            multiple={multiple}
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.label}
            popupIcon={<IconChevronDown />}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => renderInput(params)}
            onChange={(_, data) => onChange(data, field)}
            filterOptions={(options, params) => filterOptions(options, params)}
            renderOption={renderOption}
          />
        </>
      )}
      name={name}
      control={control}
    />
  )
}

export default Autocomplete
