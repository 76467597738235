import { Box, Button, Typography } from "@material-ui/core"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import useUtilsStyles from "../../../utils/utils.styles"
import SectionTitle from "../../common/title/sectionTitle/SectionTitle"
import { ReactComponent as IconUpload } from "../../../assets/svg/upload.svg"
import { ReactComponent as IconAdd } from "../../../assets/svg/add.svg"
import Link from "../../common/link/link/Link"
import { RoutePath } from "../../../routes/Routes.types"
import { IReferral } from "../../../ts/interfaces/Referral"
import { IMedicalReport } from "../../../ts/interfaces/MedicalReport"
import Dialog from "../../common/dialog/dialog/Dialog"
import UploadPatientReport from "../../form/uploadPatientReport/UploadPatientReport"
import {IPatient} from "../../../ts/interfaces/Patient"
import { DocumentsTable } from "./DocumentsTable/DocumentsTable.component"

interface IProps {
  patient: IPatient
  referrals: IReferral[]
  medicalReports: IMedicalReport[]
}

const OccupationalMedicine: FC<IProps> = ({ patient, referrals, medicalReports }) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()
  const [isDialogUploadOpened, setIsDialogUploadOpened] = useState(false)
  const params = useParams()
  const employeeId = params.id || ""

  const openDialog = () => {
    setIsDialogUploadOpened(true)
  }

  const closeDialog = () => {
    setIsDialogUploadOpened(false)
  }

  return (
    <Box>
      <SectionTitle title={t("employeeForm:occupationalMedicine")} mb="24px" />
      {(!!referrals.length || !!medicalReports.length) && (
        <Box
          mb="36px"
        >
          <DocumentsTable
            patient={patient}
            referrals={referrals}
            medicalReports={medicalReports}
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems='center'
        className={utilClasses.gap16}
      >
        <Button
          variant="outlined"
          onClick={openDialog}
          disabled={!patient.hasCompletePersonalData || !patient.active}
        >
          <IconUpload />
          <Box ml="8px">{t("employeeForm:uploadOpinion")}</Box>
        </Button>
        <Button
          component={Link}
          to={RoutePath.REFERRAL_ADD}
          state={{ employeeId: employeeId }}
          variant="outlined"
          disabled={!patient.hasCompletePersonalData || !patient.active}
        >
          <IconAdd />
          <Box ml="8px">{t("employeeForm:createReferral")}</Box>
        </Button>
        {!patient.hasCompletePersonalData && <Typography variant='body1'>{t("employeeForm:verifyPatientData")}</Typography>}
      </Box>
      <Box
        marginTop={1}
      >
        {!patient.active && <Typography variant='body1' color='error'>{t("employeeForm:inactiveEmployeeText")}</Typography>}
      </Box>
      <Dialog open={isDialogUploadOpened} onClose={closeDialog} maxWidth="sm">
        <UploadPatientReport
          patientId={employeeId}
          onButtonCancelClick={closeDialog}
        />
      </Dialog>
    </Box>
  )
}

export default OccupationalMedicine
