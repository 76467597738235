import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isFilterOpened: true,
}

export const patientsList = createSlice({
  name: "user",
  initialState,
  reducers: {
    toggleFilters: (state) => {
      state.isFilterOpened = !state.isFilterOpened
    },
  },
})

export const { toggleFilters } = patientsList.actions

export default patientsList.reducer
