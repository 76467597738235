import { FC } from "react"
import { Typography } from "@material-ui/core"
import { Variant } from "@material-ui/core/styles/createTypography"

import useStatusTextStyles from "./StatusText.styles"
import { ReactComponent as IconInfoIndicator } from "../../../assets/svg/info-indicator.svg"

interface IProps {
  variant?: Variant
  text: string
}

const StatusText: FC<IProps> = ({ variant = "body1", text }) => {
  const classes = useStatusTextStyles()

  return (
    <Typography variant={variant} className={classes.typographyWithIcon}>
      <IconInfoIndicator className={classes.icon} />
      {text}
    </Typography>
  )
}

export default StatusText
