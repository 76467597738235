import { FC } from "react"
import { Paper, TableCell, TableContainer, TableHead, Table, TableBody, TableRow } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import { IReferral } from "../../../../ts/interfaces/Referral"
import { IMedicalReport } from "../../../../ts/interfaces/MedicalReport"
import {IPatient} from "../../../../ts/interfaces/Patient"
import { useDocumentsTable } from "./DocumentsTable.hook"

interface IProps {
  patient: IPatient
  referrals: IReferral[]
  medicalReports: IMedicalReport[]
}

export const DocumentsTable: FC<IProps> = ({ patient, referrals, medicalReports }) => {
  const { t } = useTranslation()
  const {
    parsedReports,
    parsedReferrals,
  } = useDocumentsTable(patient, referrals, medicalReports)

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
      > 
        <TableHead>
          <TableRow>
            <TableCell>{t("employeeForm:tableColumnTitle:documentType")}</TableCell>
            <TableCell>{t("employeeForm:tableColumnTitle:createdAt")}</TableCell>
            <TableCell>{t("employeeForm:tableColumnTitle:preview")}</TableCell>
            <TableCell>{t("employeeForm:tableColumnTitle:validUntil")}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedReports.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.createdAt}</TableCell>
              <TableCell>{row.preview}</TableCell>
              <TableCell>{row.validUntil}</TableCell>
              <TableCell>{row.printIcon}</TableCell>
              <TableCell>{row.deleteIcon}</TableCell>
            </TableRow>
          ))}
          {parsedReferrals.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.createdAt}</TableCell>
              <TableCell>{row.preview}</TableCell>
              <TableCell>{row.validUntil}</TableCell>
              <TableCell>{row.printIcon}</TableCell>
              <TableCell>{row.deleteIcon}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}