import { FC } from "react"

import PageContentLayout from "../../layout/pageContentLayout/PageContentLayout"
import Paper from "../../components/common/paper/Paper"
import PatientsList from "../../components/module/patientsList/PatientsList"

const HomepagePage: FC = () => {
  return (
    <PageContentLayout>
      <Paper>
        <PatientsList />
      </Paper>
    </PageContentLayout>
  )
}

export default HomepagePage
