import { FC } from "react"
import { Grid } from "@material-ui/core"

import SectionTitle from "../../title/sectionTitle/SectionTitle"

interface IProps {
  title?: string
}

const FormSection: FC<IProps> = ({ children, title }) => {
  return (
    <>
      {!!title && <SectionTitle title={title} />}
      <Grid container spacing={2}>
        {children}
      </Grid>
    </>
  )
}

export default FormSection
