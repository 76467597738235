import { FC, useEffect } from "react"
import { Routes as Switch, Route, useNavigate } from "react-router-dom"

import PrivateRoute from "./PrivateRoute"

import Homepage from "../pages/Homepage"
import Login from "../pages/Login"
import EmployeeAddPage from "../pages/Employee/Add"
import EmployeeByIdPage from "../pages/Employee/Id"
import ReferralAddPage from "../pages/ReferralAddPage/ReferralAddPage"
import Page404 from "../pages/404"

import Layout from "../layout/layout/Layout"

import { RoutePath } from "./Routes.types"
import { useAppSelector } from "../hooks/storeHooks/storeHooks"
import {
  selectAuthenticated,
  selectIsInterceptorReady,
  selectUserError,
} from "../store/user/user.selectors"

const Routes: FC = () => {
  const navigate = useNavigate()
  const authenticated = useAppSelector(selectAuthenticated)
  const userError = useAppSelector(selectUserError)
  const isInterceptorReady = useAppSelector(selectIsInterceptorReady)

  useEffect(() => {
    if (userError) {
      navigate(RoutePath.LOGIN)
    }
  }, [userError])

  if (!isInterceptorReady || !authenticated) {
    return null
  }

  return (
    <Switch>
      <Route path="/" element={<Layout />}>
        <Route path={RoutePath.LOGIN} element={<Login />} />
        <Route
          path={RoutePath.HOMEPAGE}
          element={
            <PrivateRoute authenticated={authenticated}>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.EMPLOYEE_BY_ID}
          element={
            <PrivateRoute authenticated={authenticated}>
              <EmployeeByIdPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.EMPLOYEE_ADD}
          element={
            <PrivateRoute authenticated={authenticated}>
              <EmployeeAddPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.REFERRAL_ADD}
          element={
            <PrivateRoute authenticated={authenticated}>
              <ReferralAddPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RoutePath.REFERRAL_ADD}
          element={
            <PrivateRoute authenticated={authenticated}>
              <ReferralAddPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={RoutePath.PAGE404} element={<Page404 />} />
    </Switch>
  )
}

export default Routes
