import {FC, useEffect, useState} from "react"
import {Grid} from "@material-ui/core"
import {useTranslation} from "react-i18next"

import TextField from "../../../field/textField/TextField"
import DateField from "../../../field/dateField/DateField"
import CheckboxField from "../../../field/checkboxField/CheckboxField"
import {useFormContext, useWatch} from "react-hook-form"
import EmployerSelect from "../../../employerSelect/EmployerSelect"
import AccessControl from "../../../accessControl/AccessControl.component"
import {Role} from "../../../../../api/roles.types"

const PersonalDataFields: FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues, clearErrors } = useFormContext()

  const initialPesel = useState<string>(getValues("pesel"))[0]
  const initialBirthday = useState<Date | null>(getValues("birthday"))[0]

  const noPeselWatcher = useWatch({
    name: "noPesel",
  })

  useEffect(() => {
    if (noPeselWatcher) {
      clearErrors("pesel")
      setValue("pesel", "")
      setValue("birthday", initialBirthday || null)
    } else {
      clearErrors("birthday")
      setValue("birthday", null)
      setValue("pesel", initialPesel || "")
    }
  }, [noPeselWatcher])

  return (
    <>
      <Grid item xs={6}>
        <TextField
          name="firstName"
          label={t("employeeForm:name")}
          placeholder={t("employeeForm:enterName")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="lastName"
          label={t("employeeForm:lastName")}
          placeholder={t("employeeForm:enterLastName")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="email"
          label={t("employeeForm:email")}
          placeholder={t("employeeForm:enterEmail")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="phone"
          label={t("employeeForm:phone")}
          placeholder={t("employeeForm:enterPhone")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="pesel"
          label={t("employeeForm:pesel")}
          disabled={noPeselWatcher}
          placeholder={t("employeeForm:enterPesel")}
          inputMode="numeric"
          mask="99999999999"
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name="birthday"
          label={t("employeeForm:dateOfBirth")}
          disabled={!noPeselWatcher}
          placeholder={t("employeeForm:enterDateOfBirth")}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxField name="noPesel" label={t("employeeForm:noPesel")} />
      </Grid>
      <AccessControl allowedRoles={[Role.ROLE_HR_ADMIN]}>
        <Grid item xs={6}>
          <EmployerSelect
            name="employerId"
          />
        </Grid>
      </AccessControl>
      <Grid item xs={6}>
        <TextField
          name="position"
          label={t("employeeForm:position")}
          placeholder={t("employeeForm:enterPosition")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="identificationNumber"
          label={t("employeeForm:identificationNumber")}
          placeholder={t("employeeForm:enterIdentificationNumber")}
        />
      </Grid>
    </>
  )
}

export default PersonalDataFields
