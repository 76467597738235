import { FC } from "react"
import { HashRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import {CssBaseline, MuiThemeProvider, StylesProvider} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import plLocale from "date-fns/locale/pl"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import store from "../../store"
import theme from "../../themes/default/theme"
import { reactQueryClient } from "../../api/reactQuery"
import {ApolloProvider} from "@apollo/client"
import {apolloClient} from "../../api/apolloClient"
import InterceptorProvider from "./interceptorProvider/InterceptorProvider"
import UserProvider from "./userProvider/UserProvider"
import {ThemeProvider} from "styled-components"

const AppProviders: FC = ({ children }) => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={reactQueryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <Router basename="/">
            <StylesProvider injectFirst>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <UserProvider>
                    <InterceptorProvider>
                      {children}
                    </InterceptorProvider>
                  </UserProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
    </ApolloProvider>
  </Provider>
)

export default AppProviders
