import { FC } from "react"

import PageContentLayout from "../../layout/pageContentLayout/PageContentLayout"
import Paper from "../../components/common/paper/Paper"
import GoBackLink from "../../components/common/link/goBackLink/GoBackLink"
import AddReferral from "../../components/form/addReferral/AddReferral.component"

const ReferralAddPage: FC = () => {
  return (
    <PageContentLayout>
      <Paper>
        <GoBackLink />
        <AddReferral />
      </Paper>
    </PageContentLayout>
  )
}

export default ReferralAddPage