import { palette } from "../palette"

const MuiButton = {
  root: {
    padding: "8px 24px",
    maxWidth: "unset",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    "& *": {
      pointerEvents: "none", // prevent target button-label span on click event
    },
  },
  contained: {
    backgroundColor: palette.primary.main,
    borderRadius: 9999,
    boxShadow: "none",
    "&:hover, &:focus": {
      boxShadow: "none",
    },
  },
  containedPrimary: {
    "&:hover, &:focus": {
      backgroundColor: palette.primary.dark,
    },
  },
  containedSecondary: {
    backgroundColor: palette.secondary.dark,
    "&:hover, &:focus": {
      backgroundColor: palette.secondary.main,
    },
  },
  outlined: {
    borderWidth: "2px !important",
    padding: "6px 22px",
    borderRadius: 9999,
  },
  outlinedPrimary: {
    borderColor: palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
  },
  outlinedSecondary: {
    borderColor: palette.secondary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
    },
  },
  text: {
    padding: "8px 24px",
  },
}

export const buttons = {
  MuiButton,
}
