import { FC, useState } from "react"
import { Box, Grid, Tooltip } from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import { useTranslation } from "react-i18next"

import FormSection from "../../../common/form/formSection/FormSection"
import RadioFieldGroup from "../../../common/field/radioFieldGroup/RadioFieldGroup"
import RadioField from "../../../common/field/radioField/RadioField"
import Autocomplete from "../../../common/field/autocomplete/Autocomplete"
import { jobTypes, referralTypes } from "./FormFields.config"
import TextField from "../../../common/field/textField/TextField"
import { useQueryExposures } from "../../../../api/reactQuery/queries/exposures"
import FieldSkeleton from "../../../common/skeleton/fieldSkeleton/FieldSkeleton"
import StatusText from "../../../common/statusText/StatusText"
import useUtilsStyles from "../../../../utils/utils.styles"
import DateField from "../../../common/field/dateField/DateField"
import { ReactComponent as IconInfo } from "../../../../assets/svg/info.svg"
import { useFormFieldsStyles } from "./FormFields.styles"
import { useFormFields } from "./FormFields.hooks"
import { useFormContext, useWatch } from "react-hook-form"
import { ReferralTypes } from "./FormFields.enum"
import { IInputOption } from "../../../../ts/interfaces/FormField"
import { ReactComponent as IconTrash } from "../../../../assets/svg/trash.svg"
import DeleteReferralTemplateModal from "../deleteReferralTemplateModal/DeleteReferralTemplateModal.component"
import { ReferralTemplatesModel } from "../../../../api/apolloClient/query/getReferralTemplates/getReferralTemplates.types"
import { Endpoint } from "../../../../api/endpoints.types"
import axiosInstance from "../../../../api/api"
import Snackbar from "../../../common/snackbar/Snackbar"
import {
  EXTRA_MARKS_MAX_FIELD_CHARACTERS,
  MAX_INFECTION_POSSIBILITY_FIELD_CHARS,
  MAX_JOB_TITLE_DESCRIPTION_CHARS
} from "../AddReferral.validation"
import { Skeleton } from "@material-ui/lab"

type FormFieldsProps = {
  referralTemplates?: ReferralTemplatesModel[];
  refetchTemplates: () => void;
  setSelectedReferralId: (id: string) => void;
}

interface SelectedData {
  value: string;
  label: string;
}

const FormFields: FC<FormFieldsProps> = ({
  referralTemplates,
  refetchTemplates,
  setSelectedReferralId,
}) => {
  const { t } = useTranslation()
  const utilClasses = useUtilsStyles()
  const { infoIconWrapper, optionWrapper } = useFormFieldsStyles()
  const { getJobTypeTooltip, mapExposuresToFormValues } = useFormFields()
  const referralTypeSelected = useWatch({ name: "referralType" })
  const isEpidemicReferralType = referralTypeSelected === ReferralTypes.EPIDEMIC
  const formMethods = useFormContext()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<IInputOption | undefined>(undefined)
  const [isReferralTemplateDeletedSuccessfully, setIsReferralTemplateDeletedSuccessfully] = useState(false)
  const [isReferralTemplateDeleteError, setIsReferralTemplateDeleteError] = useState(false)
  const [isTemplateDataLoading, setIsTemplateDataLoading] = useState<boolean>(false)

  const { data, isLoading, isError } = useQueryExposures()
  const mappedReferralTemplates = referralTemplates?.map( template => ({
    value: template.id,
    label: template.name,
  })) ?? []

  const handleChangeTemplate = async (selectedData: SelectedData) => {
    if (null === selectedData) {
      return
    }

    const label = selectedData.label
    const value = selectedData.value
    const previousValue = formMethods.getValues("referralTemplates")?.value

    if (!referralTemplates || !label || previousValue === value) {
      return
    }

    setIsTemplateDataLoading(true)
    const { data: refferalData } = await axiosInstance(`${Endpoint.REFERRALS_TEMPLATES}/${value}`)
    if (null !== refferalData) {

      setSelectedReferralId(refferalData.id)
      formMethods.setValue("referralType", refferalData.type)
      formMethods.setValue("jobTitleDescription", refferalData.jobTitleDescription ?? "")
      formMethods.setValue("jobType", refferalData.jobType?.toString() ?? "")
      formMethods.setValue("rangeActivitiesPossibilityTransmittingInfection", refferalData.rangeActivitiesPossibilityTransmittingInfection ?? "")

      const exposureValues = mapExposuresToFormValues(refferalData.exposures, data ?? [])
      formMethods.setValue("physicalFactors", exposureValues.physicalFactors)
      formMethods.setValue("dusts", exposureValues.dusts)
      formMethods.setValue("chemicalFactors", exposureValues.chemicalFactors)
      formMethods.setValue("biologicalFactors", exposureValues.biologicalFactors)
      formMethods.setValue("otherFactors", exposureValues.otherFactors)
    }

    setIsTemplateDataLoading(false)
  }

  const renderedReferralTemplateOption = (props: IInputOption) => (
    <Box {...props} className={optionWrapper}>
      <span>{props.label}</span>
      <IconTrash onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDeleteModalOpen(true)
        setSelectedOption(props)
      }} />
    </Box>
  )

  return (
    <Box>
      <Box mb="32px">
        <FormSection title={t("addReferral:referralType")}>
          <Grid item xs={9}>
            <Autocomplete
              name="referralTemplates"
              placeholder={t("addReferral:referralTemplates:templatesListPlaceholder")}
              label={t("addReferral:referralTemplates:templatesListLabel")}
              options={mappedReferralTemplates}
              onChangeSelected={handleChangeTemplate}
              renderOption={renderedReferralTemplateOption}
            />
          </Grid>
            <Grid item xs={12}>
              {!isTemplateDataLoading
                ? (
                  <RadioFieldGroup row name="referralType">
                    {referralTypes.map((type) => (
                      <RadioField
                        key={type.value}
                        value={type.value}
                        label={type.label}
                      />
                    ))}
                  </RadioFieldGroup>
                )
                : <Skeleton width="60%" height="40px" variant="rect" />}
          </Grid>
          <Grid item xs={9}>
          {isEpidemicReferralType
          ? (
            <TextField
              key="rangeActivitiesPossibilityTransmittingInfection"
              name="rangeActivitiesPossibilityTransmittingInfection"
              label={t("addReferral:rangeActivitiesPossibilityTransmittingInfection")}
              placeholder={t("addReferral:rangeActivitiesPossibilityTransmittingInfectionLabel")}
              isLoading={isTemplateDataLoading}
              maxLength={MAX_INFECTION_POSSIBILITY_FIELD_CHARS}
              multiline
            />
          )
          : (
            <TextField
              key="jobTitleDescription"
              name="jobTitleDescription"
              label={t("addReferral:jobTitleDescription")}
              placeholder={t("addReferral:jobTitleDescriptionLabel")}
              maxLength={MAX_JOB_TITLE_DESCRIPTION_CHARS}
              isLoading={isTemplateDataLoading}
              multiline
            />
          )}
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="extraRemarks"
              label={t("addReferral:extraRemarksLabel")}
              placeholder={t("addReferral:extraRemarksPlaceholder")}
              maxLength={EXTRA_MARKS_MAX_FIELD_CHARACTERS}
              isLoading={isTemplateDataLoading}
              multiline
            />
          </Grid>
          {isEpidemicReferralType && (
            <Grid item xs={12}>
              <RadioFieldGroup
                row
                name="jobType"
                id="jobType"
              >
                {jobTypes.map((type) => (
                  <Box
                    my={2}
                    key={type.value}
                  >
                    <RadioField
                      value={type.value}
                      checkedIcon={<CheckBoxIcon color="primary"/>}
                      icon={<CheckBoxOutlineBlankIcon />}
                      label={(
                        <>
                          {type.label}
                          <Tooltip
                            title={getJobTypeTooltip(type.value) ?? ""}
                            placement="bottom-start"
                            className={infoIconWrapper}
                          >
                            <IconInfo />
                          </Tooltip>
                        </>
                      )}
                    />
                  </Box>
                ))}
              </RadioFieldGroup>
            </Grid>
          )}
        </FormSection>
      </Box>
      {isError && (
        <Box className={utilClasses.textErrorMain}>
          <StatusText text={t("validations:failedToFetch")} />
        </Box>
      )}
      {!isError && !isEpidemicReferralType && (
        <FormSection title={t("addReferral:chooseExposuresFactors")}>
          <Grid item xs={9}>
            {data && !isTemplateDataLoading && (
              <Autocomplete
                multiple
                name="physicalFactors"
                placeholder={t("addReferral:enterExposureNameOrChooseFromList")}
                label={t("addReferral:physicalFactors")}
                options={data
                  .filter((exposure) => exposure.type === "physical")
                  .map((exposure) => {
                    return { value: exposure.id, label: exposure.name }
                  })}
              />
            )}
            {(isLoading || isTemplateDataLoading) && <FieldSkeleton />}
          </Grid>
          <Grid item xs={9}>
            {data && !isTemplateDataLoading && (
              <Autocomplete
                multiple
                name="dusts"
                loading={isTemplateDataLoading}
                placeholder={t("addReferral:enterExposureNameOrChooseFromList")}
                label={t("addReferral:dusts")}
                options={data
                  .filter((exposure) => exposure.type === "dust")
                  .map((exposure) => {
                    return { value: exposure.id, label: exposure.name }
                  })}
              />
            )}
            {(isLoading || isTemplateDataLoading) && <FieldSkeleton />}
          </Grid>
          <Grid item xs={9}>
            {data && !isTemplateDataLoading && (
              <Autocomplete
                multiple
                name="chemicalFactors"
                loading={isTemplateDataLoading}
                placeholder={t("addReferral:enterExposureNameOrChooseFromList")}
                label={t("addReferral:chemicalFactors")}
                options={data
                  .filter((exposure) => exposure.type === "chemical")
                  .map((exposure) => {
                    return { value: exposure.id, label: exposure.name }
                  })}
              />
            )}
            {(isLoading || isTemplateDataLoading) && <FieldSkeleton />}
          </Grid>
          <Grid item xs={9}>
            {data && !isTemplateDataLoading && (
              <Autocomplete
                multiple
                name="biologicalFactors"
                loading={isTemplateDataLoading}
                placeholder={t("addReferral:enterExposureNameOrChooseFromList")}
                label={t("addReferral:biologicalFactors")}
                options={data
                  .filter((exposure) => exposure.type === "biological")
                  .map((exposure) => {
                    return { value: exposure.id, label: exposure.name }
                  })}
              />
            )}
            {(isLoading || isTemplateDataLoading) && <FieldSkeleton />}
          </Grid>
          <Grid item xs={9}>
            {data && !isTemplateDataLoading && (
              <Autocomplete
                multiple
                name="otherFactors"
                loading={isTemplateDataLoading}
                placeholder={t("addReferral:enterExposureNameOrChooseFromList")}
                label={t("addReferral:otherFactorsIncludingDangerous")}
                options={data
                  .filter((exposure) => exposure.type === "other")
                  .map((exposure) => {
                    return { value: exposure.id, label: exposure.name }
                  })}
                canCreateNewOption={true}
              />
            )}
            {(isLoading || isTemplateDataLoading) && <FieldSkeleton />}
          </Grid>
        </FormSection>
      )}
      <Box mt="32px">
        <FormSection>
          <Grid item xs={3}>
            <DateField
              name="referralDeadlineDate"
              label={t("addReferral:referralDeadlineDate")}
            />
          </Grid>
        </FormSection>
      </Box>
      <DeleteReferralTemplateModal
        open={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        selectedOption={selectedOption}
        refetchTemplates={refetchTemplates}
        setIsReferralTemplateDeletedSuccessfully={(state) => setIsReferralTemplateDeletedSuccessfully(state)}
        setIsReferralTemplateDeleteError={(state) => setIsReferralTemplateDeleteError(state)}
      />
      <Snackbar
        isVisible={isReferralTemplateDeletedSuccessfully}
        text={t("addReferral:referralTemplates:deleteTemplateSuccess")}
      />
      <Snackbar
        isVisible={isReferralTemplateDeleteError}
        text={t("addReferral:referralTemplates:deleteTemplateError")}
        severity="error"
      />
    </Box>
  )
}

export default FormFields
