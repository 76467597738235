const MuiTypography = {
  variantMapping: {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    body1: "p",
    body2: "p",
    subtitle1: "p",
    subtitle2: "p",
    button: "span",
  },
}

export const typography = {
  MuiTypography,
}
