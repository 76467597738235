import { FC } from "react"

import { KeyboardDatePicker } from "@material-ui/pickers"
import get from "lodash.get"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { IProps } from "./DateField.types"
import FieldLabel from "../fieldLabel/FieldLabel"

const DateField: FC<IProps> = ({
  name,
  label,
  error,
  errorMessage,
  disabled,
  placeholder,
  variant = "outlined",
  format = "yyyy MM dd",
  disablePast,
}) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const fieldError = get(errors, name, false)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {!!label && <FieldLabel>{label}</FieldLabel>}
          <KeyboardDatePicker
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            inputRef={field.ref}
            inputVariant={variant}
            format={format}
            fullWidth
            disabled={disabled}
            error={error || !!fieldError}
            helperText={
              errorMessage ||
              (fieldError.message && typeof fieldError.message === "string"
                ? t(fieldError.message)
                : t(fieldError.message?.key, fieldError.message?.values))
            }
            placeholder={placeholder}
            disablePast={disablePast}
          />
        </>
      )}
    />
  )
}

export default DateField
