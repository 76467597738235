import {useEffect, useRef} from "react"
import {useAppDispatch, useAppSelector} from "../../../../hooks/storeHooks/storeHooks"
import {selectUserData} from "../../../../store/user/user.selectors"
import axiosInstance from "../../../../api/api"
import {setIsInterceptorReady} from "../../../../store/user/user.slice"

export const useSetAuthorizationHeader = () => {
  const dispatch = useAppDispatch()
  const interceptorId = useRef<number>()
  const userData = useAppSelector(selectUserData)

  const token = userData?.token?.accessToken

  useEffect(() => {
    if (interceptorId.current !== undefined) {
      axiosInstance.interceptors.request.eject(interceptorId.current)
    }

    interceptorId.current = axiosInstance.interceptors.request.use(
      (config) => {
        if (token && config.headers && !config._retry) {
          config.headers["Authorization"] = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    dispatch(setIsInterceptorReady())

    return () => {
      if (interceptorId.current !== undefined) {
        axiosInstance.interceptors.request.eject(interceptorId.current)
      }
    }
  }, [token])
}
