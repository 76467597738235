import { FC } from "react"
import { Outlet } from "react-router-dom"

import Header from "../../components/header/Header"
import useLayoutStyles from "./Layout.styles"

const Layout: FC = () => {
  const classes = useLayoutStyles()

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.contentWrapper}>
        <Outlet />
      </main>
    </div>
  )
}

export default Layout
