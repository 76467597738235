import { makeStyles, createStyles, Theme } from "@material-ui/core"

const usePatientsTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      maxWidth: "100%",
      overflowX: "auto",
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
      "&::-webkit-scrollbar": {
        height: "6px",
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        background: theme.palette.grey[100],
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.palette.primary.light,
      },
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableHead: {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    tableRow: {
      boxShadow: "inset 0px -1px 0px #EBEBEB",
      "&:last-child": {
        boxShadow: "none",
      },
      "& th, & td": {
        padding: "12px 16px 12px 0",
        wordBreak: "break-word",
        "&:first-child": {
          paddingLeft: "16px",
        },
        "& tr": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    headerText: {
      color: theme.palette.grey[400],
      fontWeight: 700,
      textAlign: "start",
    },
    bodyText: {
      color: theme.palette.grey[400],
      fontWeight: 700,
      textAlign: "start",
    },
    actionButton: {
      border: "none",
      borderRadius: 9999,
      cursor: "pointer",
      padding: "2px",
      width: "28px",
      height: "28px",
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  })
)

export default usePatientsTableStyles
