import { FC } from "react"

import { ReactComponent as IconClose } from "../../../../assets/svg/close.svg"
import useButtonCloseStyles from "./ButtonClose.styles"

interface IProps {
  onClick: () => void
}

const ButtonClose: FC<IProps> = ({ onClick }) => {
  const classes = useButtonCloseStyles()

  return (
    <button onClick={onClick} className={classes.root}>
      <IconClose height="20px" width="20px" />
    </button>
  )
}

export default ButtonClose
