import { FC, useEffect, useState } from "react"
import { Box, Button, Divider, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Pagination } from "@material-ui/lab"
import { useSearchParams } from "react-router-dom"

import { ReactComponent as IconUser } from "../../../assets/svg/user.svg"
import useUtilsStyles from "../../../utils/utils.styles"
import PatientsTable from "./patientsTable/PatientsTable"
import { RoutePath } from "../../../routes/Routes.types"
import Link from "../../common/link/link/Link"
import {
  IParamsGetPatients,
  useQueryPatients,
} from "../../../api/reactQuery/queries/patients"
import StatusText from "../../common/statusText/StatusText"
import { getPagesCount } from "../../../utils/pagination"
import { scrollToTop } from "../../../utils/scroll"
import TableSkeleton from "./patientsTable/tableSkeleton/TableSkeleton"
import FiltersForm from "./patientsTable/filtersForm/FiltersForm"
import { defaultValues } from "./patientsTable/filtersForm/FiltersForm.config"
import { format } from "date-fns"
import {useGetHrUserEmployer} from "../../../hooks/useGetHrUserEmployer"
import {EmployeeStatus} from "../../../ts/enums/EmployeeStatus"

const PatientsList: FC = () => {
  const utilClasses = useUtilsStyles()
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const initialEmployer = searchParams.get("employer") || ""
  const initialFirstName = searchParams.get("firstName") || ""
  const initialLastName = searchParams.get("lastName") || ""
  const initialIdNumber = searchParams.get("idNumber") || ""
  const initialBundle = searchParams.get("bundle") || ""
  const initialStatus =
    searchParams.get("status") === "active"
      ? EmployeeStatus.ACTIVE
      : searchParams.get("status") === "inactive"
      ? EmployeeStatus.INACTIVE
      : ""
  const initialActiveSince = searchParams.get("activeSince") || ""
  const initialActiveUntil = searchParams.get("activeUntil") || ""
  const initialValidSince = searchParams.get("validSince") || ""
  const initialValidUntil = searchParams.get("validUntil") || ""
  const initialOccupationalMedicineStatus =
    searchParams.get("occupationalMedicineStatus") || ""
  const initialOverdueReports = searchParams.get("overdueReports") || ""

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
  const [queryParams, setQueryParams] = useState<
    Omit<IParamsGetPatients, "page">
  >({
    employer: initialEmployer,
    firstName: initialFirstName,
    lastName: initialLastName,
    idNumber: initialIdNumber,
    bundle: initialBundle,
    status: initialStatus,
    activeSince: initialActiveSince,
    activeUntil: initialActiveUntil,
    validSince: initialValidSince,
    validUntil: initialValidUntil,
    occupationalMedicineStatus: initialOccupationalMedicineStatus,
    overdueReports: initialOverdueReports === "true",
  })

  const { data, isLoading, isError } = useQueryPatients({
    page,
    ...queryParams,
  })
  useGetHrUserEmployer()

  const onPageChange = (page: number) => {
    setPage(page)
    scrollToTop()
  }

  useEffect(() => {
    const params = new URLSearchParams()

    if (queryParams.employer) params.append("employer", queryParams.employer)
    if (queryParams.firstName) params.append("firstName", queryParams.firstName)
    if (queryParams.lastName) params.append("lastName", queryParams.lastName)
    if (queryParams.idNumber) params.append("idNumber", queryParams.idNumber)
    if (queryParams.bundle) params.append("bundle", queryParams.bundle)
    if (queryParams.status) params.append("status", queryParams.status)
    if (queryParams.activeSince)
      params.append("activeSince", queryParams.activeSince)
    if (queryParams.activeUntil)
      params.append("activeUntil", queryParams.activeUntil)
    if (queryParams.validSince)
      params.append("validSince", queryParams.validSince)
    if (queryParams.validUntil)
      params.append("validUntil", queryParams.validUntil)
    if (queryParams.occupationalMedicineStatus)
      params.append(
        "occupationalMedicineStatus",
        queryParams.occupationalMedicineStatus
      )
    if (queryParams.overdueReports)
      params.append("overdueReports", `${queryParams.overdueReports}`)

    if (params.toString() !== searchParams.toString()) {
      setPage(1)
      setSearchParams(params.toString())
    }
  }, [page, queryParams])

  return (
    <Box width="100%">
      <FiltersForm
        initialValues={{
          ...defaultValues,
          employer: initialEmployer,
          firstName: initialFirstName,
          lastName: initialLastName,
          idNumber: initialIdNumber,
          bundle: initialBundle,
          status: initialStatus,
          activeSince: initialActiveSince ? new Date(initialActiveSince) : null,
          activeUntil: initialActiveUntil ? new Date(initialActiveUntil) : null,
          validSince: initialValidSince ? new Date(initialValidSince) : null,
          validUntil: initialValidUntil ? new Date(initialValidUntil) : null,
          occupationalMedicineStatus: initialOccupationalMedicineStatus,
          showPatientsWithExpiredMedicine: initialOverdueReports === "true",
        }}
        onSubmit={({
          employer,
          firstName,
          lastName,
          idNumber,
          bundle,
          status,
          activeSince,
          activeUntil,
          validSince,
          validUntil,
          occupationalMedicineStatus,
          showPatientsWithExpiredMedicine,
        }) => {
          setQueryParams({
            employer,
            firstName,
            lastName,
            idNumber,
            bundle,
            status,
            activeSince: activeSince ? format(activeSince, "yyyy-MM-dd") : "",
            activeUntil: activeUntil ? format(activeUntil, "yyyy-MM-dd") : "",
            validSince: validSince ? format(validSince, "yyyy-MM-dd") : "",
            validUntil: validUntil ? format(validUntil, "yyyy-MM-dd") : "",
            occupationalMedicineStatus,
            overdueReports: showPatientsWithExpiredMedicine,
          })
        }}
      />
      <Box my="24px">
        <Divider />
      </Box>
      <Box display="flex" justifyContent="end" mb="32px">
        <Link to={RoutePath.EMPLOYEE_ADD}>
          <Button>
            <Box mr="8px" height="24px" flexShrink={0}>
              <IconUser height="24px" width="24px" />
            </Box>
            <Typography variant="subtitle1" className={utilClasses.fontBold}>
              {t("usersList:addEmployee")}
            </Typography>
          </Button>
        </Link>
      </Box>
      {isLoading && <TableSkeleton />}
      {isError && (
        <Box px="16px" py="12px" className={utilClasses.textErrorMain}>
          <StatusText text={t("validations:failedToFetch")} />
        </Box>
      )}
      {data?.data && (
        <Box width="100%">
          <PatientsTable data={data.data} />
          <Box display="flex" justifyContent="end" mt="24px">
            <Pagination
              page={page}
              count={getPagesCount(data?.itemsPerPage, data?.totalItems)}
              onChange={(_, page) => onPageChange(page)}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PatientsList
