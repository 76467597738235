import { FC, useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { useTranslation } from "react-i18next"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

import { useMutationPutMedicalReports } from "../../../../../api/reactQuery/mutations/medicalReports"
import Snackbar from "../../../../common/snackbar/Snackbar"

interface IProps {
  id: string
  validUntil: Date
}

export const ValidUntilPicker: FC<IProps> = ({ id, validUntil }) => {
  const [expirationDate, setExpirationDate] = useState<Date>(validUntil)
  const { t } = useTranslation()

  const {
    mutate: putMedicalOpinion,
    data,
    isSuccess: isSuccessPut,
    isError: isErrorPut,
  } = useMutationPutMedicalReports()

  const onDateChange = (e: MaterialUiPickersDate) => {
    const newDate = e as Date
    putMedicalOpinion({ id, validUntil: newDate.toISOString() })
  }

  useEffect(() => {
    if (data) {
      setExpirationDate(new Date(data.validUntil))
    }
  }, [data])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        flexBasis="220px"
      >
        <DatePicker
          value={expirationDate}
          onChange={(e) => {
            onDateChange(e)
          }}
          fullWidth
          inputVariant="outlined"
          format="yyyy MM dd"
        />
      </Box>
      <Snackbar
        isVisible={isErrorPut}
        text={t("validations:errorOccuredTryAgain")}
        severity="error"
      />
      <Snackbar
        isVisible={isSuccessPut}
        text={t("employeeForm:changedExpirationDate")}
      />
    </Box>
  )
}
