import { FC } from "react"
import { FormControlLabel, Radio } from "@material-ui/core"

import { IProps } from "./RadioField.types"

const RadioField: FC<IProps> = ({ value, label, ...props }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" {...props} />}
      label={label}
    />
  )
}

export default RadioField
